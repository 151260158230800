import React, { useRef, useState, useEffect } from "react";

import Sidebar from "./Sidebar/Sidebar";

import "react-toastify/dist/ReactToastify.css";
import "./Template1.css";
import { useParams } from "react-router-dom";

const VideoProcessingPage = () => {
  const [videoData, setVideoData] = useState([]);
  const [showMoreColumns, setShowMoreColumns] = useState(false);

  const handleToggleColumns = (index) => {
    setShowMoreColumns((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const { MRID } = useParams();
  //const videoData = [
  //   {
  //     DrName: "Dr. John Doe",
  //     DOC: "10:00 AM",
  //     TOC: "10:05 AM",
  //     processTime: "5 mins",
  //     downloadLink: "/path/to/download",
  //     status: "Completed",
  //   },

  //   {
  //     DrName: "Dr. Fawad Shaikh",
  //     DOC: "10:00 AM",
  //     TOC: "10:05 AM",
  //     processTime: "5 mins",
  //     downloadLink: "/path/to/download",
  //     status: "Completed",
  //   },
  //   // Add more rows as needed
  // ];

  function convertSecondsToMinutes(seconds) {
    const minutes = Math.floor(seconds / 60);  // Get the number of full minutes
    const remainingSeconds = seconds % 60;    // Get the remaining seconds
    const formattedSeconds = (remainingSeconds < 10 ? '0' : '') + remainingSeconds; // Format seconds with leading zero if necessary
    return `${minutes}.${formattedSeconds}`;
  }

  // Helper function to calculate the time difference
const calculateProcessTime = (start, end) => {
  const [startHour, startMinute, startSecond] = start.split(':').map(Number);
  const [endHour, endMinute, endSecond] = end.split(':').map(Number);

  const startDate = new Date(0, 0, 0, startHour, startMinute, startSecond);
  const endDate = new Date(0, 0, 0, endHour, endMinute, endSecond);

  let diff = (endDate - startDate) / 1000; // Difference in seconds

  // If the process spans across midnight, adjust the difference
  if (diff < 0) {
    diff += 24 * 3600; // Add 24 hours in seconds
  }

  const minutes = Math.floor(diff / 60); // Convert to minutes
  const seconds = diff % 60; // Remaining seconds

  return `${minutes}.${seconds}`
};

 useEffect(() => {
  // Function to fetch data from API
  const fetchVideoData = async () => {
    try {
      const response = await fetch(
        `https://dummy.digilateral.com/api/auth/userTrackUsage/${MRID}`
      ); // Replace with your API endpoint
      const data = await response.json();
      setVideoData(data); // Assuming the API returns an array of video data
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };

  // Initial fetch when the component mounts
  fetchVideoData();

  // Set up the interval to fetch data every 1 minute (60000 milliseconds)
  const intervalId = setInterval(fetchVideoData, 60000);
  
  // Cleanup function to clear the interval when the component unmounts
  return () => clearInterval(intervalId);
},[]);




  return (
    <div className="flex relative justify-center items-center flex-col overflow-x-hidden p-4 md:top-[80px]">
      <div className="w-[100vw]">
        <Sidebar />
      </div>

      <h2 className="text-2xl font-bold mb-6">Processed Video </h2>
      <div >
        <p className="relative sm:top-[-10px] bg-yellow-300 font-bold text-red-500 text-center top-[-10px]">
          
        </p>
      </div>
      <div className="w-full max-w-4xl mx-auto overflow-x-auto">
        {/* Desktop Table */}
        <div className="hidden md:block">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-200 text-gray-600 uppercase text-xs md:text-sm leading-normal">
              <tr>
                <th className="py-2 md:py-3 px-2 md:px-4 text-center font-semibold">
                  Sr No.
                </th>
                <th className="py-2 md:py-3 px-2 md:px-4 text-left font-semibold">
                  Dr Name
                </th>
                <th className="py-2 md:py-3 px-2 md:px-4 text-center font-semibold">
                  Creation Date
                </th>
                <th className="py-2 md:py-3 px-2 md:px-4 text-center font-semibold">
                  Time Initiated
                </th>
                <th className="py-2 md:py-3 px-2 md:px-4 text-center font-semibold">
                  Time Completed
                </th>
                <th className="py-2 md:py-3 px-2 md:px-4 text-center font-semibold">
                  Process Duration (min)
                </th>
                <th className="py-2 md:py-3 px-2 md:px-4 text-center font-semibold">
                  Status
                </th>
                <th className="py-2 md:py-3 px-2 md:px-4 text-center font-semibold">
                  Size (MB)
                </th>
                <th className="py-2 md:py-3 px-2 md:px-4 text-center font-semibold">
                  Download
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-xs md:text-sm">
              {[...videoData].reverse().map((row, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td className="py-1 md:py-2 px-2 text-center">
                    {videoData.length - index}
                  </td>{" "}
                  {/* Sr No. */}
                  <td className="py-1 md:py-2 px-2 text-left whitespace-nowrap text-ellipsis overflow-hidden max-w-[150px]">
                    {row.doctorName}
                  </td>
                  <td className="py-1 md:py-2 px-2 text-center">
                    {row.dateOfCreation.split('-').reverse().join('-')}
                  </td>
                  <td className="py-1 md:py-2 px-2 text-center">
                    {row.timeCreation}
                  </td>
                  <td className="py-1 md:py-2 px-2 text-center">
                    {row.timeCompleted}
                  </td>
                  <td className="py-1 md:py-2 px-2 text-center">
                  {/* {
                    !isNaN(row.processTime)
                      ? (row.processTime
                          ? convertSecondsToMinutes(parseInt(row.processTime.split(" ")[0], 10))  // Convert seconds to minutes and seconds
                          : parseFloat(row.processTime.split(" ")[0]).toFixed(2) + " min"          // Use minutes as is
                        )
                      : "-"
                  } */}
                  {
          row.timeCreation && row.timeCompleted
            ? calculateProcessTime(row.timeCreation, row.timeCompleted)
            : "-"
        }
                  </td>
                  <td className="py-1 md:py-2 px-2 text-center">
                    <span
                      className={`${
                        row.Status === "Success"
                          ? "text-green-500"
                          : row.Status === "Processing"
                          ? "text-yellow-500"
                          : "text-red-500"
                      }`}
                    >
                      {row.Status}
                    </span>
                  </td>
                  <td className="py-1 md:py-2 px-2 text-center">
                    {row.MBSize}
                  </td>
                  <td className="py-1 md:py-2 px-2 text-center">
                    <button
                      onClick={() =>
                        row.Status === "Success" && window.open(row.shortUrl)
                      }
                      className={`${
                        row.Status === "Failed" || row.Status === "Processing"
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-orange-500 hover:bg-orange-600"
                      } text-white py-1 md:py-2 px-2 md:px-3 rounded-md text-center inline-block focus:outline-none focus:ring-2 ${
                        row.Status === "Failed" || row.Status === "Processing"
                          ? "focus:ring-gray-400"
                          : "focus:ring-orange-500"
                      }`}
                      disabled={
                        row.Status === "Failed" || row.Status === "Processing"
                      }
                    >
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Mobile Table */}
        <div className="relative block md:hidden w-full ">
          <div className="bg-gray shadow-md rounded-lg overflow-x-auto">
            {/* //<div className="p-4 overflow-x-auto"> */}
              <table className="w-full">
                <thead className="bg-gray-200 text-gray-600 uppercase text-xs md:text-sm leading-normal">
                  <tr>
                    <th className="py-2 md:py-3 px-1 text-center">Sr No.</th>
                    <th className="py-2 md:py-3 px-2 text-left">Dr Name</th>
                    <th className="py-2 md:py-3 px-2 text-center">Download</th>
                    <th className="py-2 md:py-3 px-2 text-center"></th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-xs md:text-sm">
                  {[...videoData].reverse().map((row, index) => (
                    <React.Fragment key={index}>
                      <tr className="relative border-b border-gray-200 hover:bg-gray-100">
                        <td className="py-2 md:py-3 px-1 text-center">
                          {videoData.length - index}
                        </td>
                        <td className="py-2 md:py-3 px-2 text-left">
                          {row.doctorName}
                        </td>
                        <td className="relative py-2 md:py-3 text-center">
                          <button
                            onClick={() =>
                              row.Status === "Success" &&
                              window.open(row.shortUrl)
                            }
                            className={`${
                              row.Status === "Failed" ||
                              row.Status === "Processing"
                                ? "bg-gray-400 cursor-not-allowed"
                                : "bg-orange-500 hover:bg-orange-600"
                            } text-white py-1 md:py-2 px-2 md:px-3 rounded-md text-center inline-block focus:outline-none focus:ring-2 ${
                              row.Status === "Failed" ||
                              row.Status === "Processing"
                                ? "focus:ring-gray-400"
                                : "focus:ring-orange-500"
                            }`}
                            disabled={
                              row.Status === "Failed" ||
                              row.Status === "Processing"
                            }
                          >
                            Download
                          </button>
                        </td>
                        <td
                          colSpan="2"
                          className="relative py-2 md:py-3 text-left"
                        >
                          <button
                            onClick={() => handleToggleColumns(index)}
                            className="bg-orange-500 text-white py-1 px-2 rounded-md"
                          >
                            {showMoreColumns[index] ? "-" : "+"}
                          </button>
                        </td>
                      </tr>
                      {showMoreColumns[index] && (
                        <tr className="relative border-b border-gray-200 bg-gray-100 w-full">
                          <td colSpan="4" className="py-2 md:py-3 px-2">
                            <table className="w-full">
                              <tbody>
                                <tr className="bg-gray-200 text-gray-600 uppercase text-xs md:text-sm leading-normal">
                                  <td className="py-2 md:py-3 px-2 text-center font-semibold">
                                    Creation Date
                                  </td>
                                  <td className="py-2 md:py-3 px-2 text-center font-semibold">
                                    Time Initiated
                                  </td>
                                  <td className="py-2 md:py-3 px-2 text-center font-semibold">
                                    Time Completed
                                  </td>
                                  <td className="py-2 md:py-3 px-2 text-center font-semibold">
                                    Process Duration
                                  </td>
                                  <td className="py-2 md:py-3 px-2 text-center font-semibold">
                                    Size(mb)
                                  </td>
                                  <td className="py-2 md:py-3 px-2 text-center font-semibold">
                                    Status
                                  </td>
                                </tr>
                                <tr>
                                  <td className="py-2 md:py-3 px-2 text-center text-[10px]">
                                   {row.dateOfCreation.split('-').reverse().join('-')}
                                  </td>
                                  <td className="py-2 md:py-3 px-2 text-center">
                                    {row.timeCreation}
                                  </td>
                                  <td className="py-2 md:py-3 px-2 text-center">
                                    {row.timeCompleted}
                                  </td>
                                  <td className="py-2 md:py-3 px-2 text-center">
                                  {/* {
                                  !isNaN(row.processTime)
                                    ? (row.processTime
                                        ? convertSecondsToMinutes(parseInt(row.processTime.split(" ")[0], 10))  // Convert seconds to minutes and seconds
                                        : parseFloat(row.processTime.split(" ")[0]).toFixed(2) + " min"          // Use minutes as is
                                      )
                                    : "-"
                                } */}

                                    {
                                      row.timeCreation && row.timeCompleted
                                        ? calculateProcessTime(row.timeCreation, row.timeCompleted)
                                        : "-"
                                    }
                                  </td>
                                  <td className="py-1 md:py-2 px-2 text-center">
                                    {row.MBSize}
                                  </td>
                                  <td className="py-2 md:py-3 px-2 text-center">
                                    <span
                                      className={`${
                                        row.Status === "Success"
                                          ? "text-green-500"
                                          : row.Status === "Processing"
                                          ? "text-yellow-500"
                                          : "text-red-500"
                                      }`}
                                    >
                                      {row.Status}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            {/* //</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoProcessingPage;


