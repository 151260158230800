import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar/Sidebar.jsx";
//import ProfileImg from "../../../assets/react.svg";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Profile = () => {
  const pageName = "Profile";
  const { MRID } = useParams();
  const [mrData, setMrData] = useState(null);
  const [userProfile, setUserProfile] = useState({
    HQ: "",
    Email: "",
    ZONE: "",
    FSOName: "",
    FSOSapCode: "",
  });
  const [editableEmail, setEditableEmail] = useState("");
  const ObjId = localStorage.getItem("mrID");
  const navigate = useNavigate();

  const loginSuccess = () => {
    toast.success("Email Update Successfully !", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "mt-10",
      onClose: () => {
        navigate(`/home/${MRID}`)
      },
    });
  };

  useEffect(() => {
    const fetchmrData = async () => {
      try {
        const response = await fetch(
          `https://dummy.digilateral.com/api/auth/getProfileDataMr/${MRID}`
        );
        const data = await response.json();

        if (response.ok) {
          setUserProfile({
            HQ: data.user.HQ,
            Email: data.user.Email,
            ZONE: data.user.ZONE,
            FSOName: data.user.FSOName,
            FSOSapCode: data.user.FSOSapCode,
          });
          console.log("User Data:", data.user);
        } else {
          console.error("Failed to fetch user data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchmrData();
  }, []);



  const handleSave = async () => {
    try {
      const response = await fetch(
        `https://dummy.digilateral.com/api/auth/updateProfile/${ObjId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Email: editableEmail,
            // Add other fields if needed
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          Email: editableEmail,
        }));
        localStorage.setItem('EmailId', editableEmail);
        loginSuccess()
        console.log("Profile updated successfully:", data);
      } else {
        console.error("Failed to update profile:", data.message);
      }
      // navigate(`/home/${MRID}`);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  useEffect(() => {
    // Assuming userProfile.Email is populated from an API call or some other logic
    setEditableEmail(userProfile.Email);
  }, [userProfile.Email]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
  <div className="grid grid-cols-2 h-full w-full">
    <Sidebar />

    <div
      className="sm:w-[82vw] w-[92vw] bg-white relative sm:left-[250px] sm:top-[100px] h-full left-[18px]"
    >
      <div className="mt-[100px] w-full">
        <div className="relative sm:top-[-60px]">
          <img
            src={require("../components/assets/image.png")}
            alt="Profile Image"
            className="relative w-16 h-16 mb-4 mx-auto md:left-[-3px]"
          />
          <div className="relative flex justify-center sm:top-[-10px] top-[-10px]">
            <label className="leading-loose text-black-400">
              {userProfile.FSOName}
            </label>
          </div>
          <div className="relative flex justify-center sm:top-[-10px] top-[-10px]">
            <label className="leading-loose text-black-400">
              {userProfile.FSOSapCode}
            </label>
          </div>
        </div>

        <div className="relative mb-4 w-full grid px-4 sm:px-20 gap-y-6 grid-cols-1 sm:grid-cols-2 gap-32 sm:w-[80vw] mx-auto">
          <div className="flex flex-col">
            <label className="leading-loose text-gray-400">
              Email<span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              placeholder=""
              value={editableEmail}
              onChange={(e) => setEditableEmail(e.target.value)}
              className="px-4 py-2 border-2 rounded-full w-full sm:text-sm focus:outline-none text-gray-600"
            />
          </div>
          <div className="flex flex-col">
            <label className="leading-loose text-gray-400">
              Zone<span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              placeholder=""
              value={userProfile.ZONE}
              disabled
              className="px-4 py-2 border-2 rounded-full w-full sm:text-sm focus:outline-none text-gray-600"
            />
          </div>

          <div className="pt-4 flex justify-center">
            <button
              onClick={handleSave}
              className="bg-orange-400 flex justify-center items-center w-full sm:w-40 text-white px-4 py-2.5 shadow-md rounded-full focus:outline-none relative sm:left-[330px]"
            >
              Save
            </button>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  </div>
</>


  );
};


export default Profile;

