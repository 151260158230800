import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";

function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [MRID, setMRID] = useState("");
  const [PASSWORD, setPassword] = useState("");
  const [forgotMRID, setForgotMRID] = useState("");
  const [showModal, setShowModal] = useState(false);

  const loginSuccess = (username) => {
    toast.success("Login Success !", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      onClose: () => {
        navigate(`/home/${MRID}`, {
          state: { username: username },
        });
      },
    });
  };

  const failed = () => {
    toast.error("Wrong MRID or Password!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  // async function Submit(e) {
  //   e.preventDefault();

  //   try {
  //     const response = await fetch(
  //       " https://dummy.digilateral.com/api/auth/login",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ MRID, PASSWORD }),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const data = await response.json();

  //     if (data.msg === "Login Successful") {
  //       setUsername(data.user.USERNAME);
  //       localStorage.setItem("mrID", data.user._id);
  //       loginSuccess(data.user.USERNAME);
  //     } else if (data.msg === "Incorrect Password") {
  //       // alert("Incorrect Password");
  //       failed();
  //     } else {
  //       // Handle other cases if needed
  //       alert("Unexpected response from server");
  //     }
  //   } catch (error) {
  //     console.error("Error during login:", error);
  //     // Handle the error appropriately, e.g., show a generic error message to the user
  //     // alert("An error occurred during login. Please try again.");
  //     failed();
  //   }
  // }

  async function Submit(e) {
    e.preventDefault();

    // Define the special MRID and PASSWORD
    const specialMRID = "D1234";
    const specialPassword = "12345";

    const specialMRID1 = "T1234";
    const specialPassword1 = "12345";

    const specialMRID2 = "G1234";
    const specialPassword2 = "12345";

    // Check if the provided MRID and PASSWORD match the special condition
    // if (MRID === specialMRID && PASSWORD === specialPassword) {
    //   // Handle the dummy user login locally
    //   const dummyUser = {
    //     USERNAME: "DummyUser",
    //     _id: "987654321",
    //   };

    //   // Simulate a successful login
    //   setUsername(dummyUser.USERNAME);
    //   localStorage.setItem("mrID", dummyUser._id);
    //   loginSuccess(dummyUser.USERNAME);
    // } else 
    if (MRID === specialMRID2 && PASSWORD === specialPassword2) {
      // Handle the dummy user login locally
      const dummyUser = {
        USERNAME: "Typhoid",
        _id: "7864389123",
      };
      setUsername(dummyUser.USERNAME);
      localStorage.setItem("mrID", dummyUser._id);
      loginSuccess(dummyUser.USERNAME);
    } else if (MRID === specialMRID1 && PASSWORD === specialPassword1) {
      // Handle the dummy user login locally
      const dummyUser = {
        USERNAME: "TeacherUser",
        _id: "1098643728",
      };
      setUsername(dummyUser.USERNAME);  
      localStorage.setItem("mrID", dummyUser._id);
      loginSuccess(dummyUser.USERNAME);
    } else {
      // Perform the usual login logic
      try {
        const response = await fetch(
          "https://dummy.digilateral.com/api/auth/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ MRID, PASSWORD }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.msg === "Login Successful") {
          setUsername(data.user.USERNAME);
          localStorage.setItem("mrID", data.user._id);
          loginSuccess(data.user.USERNAME);
          localStorage.setItem("BusinessUnit", data.user?.FSOBussinessunit);
          localStorage.setItem("EmailId", data.user?.Email);
        } else if (data.msg === "Incorrect Password") {
          failed();
        } else {
          alert("Unexpected response from server");
        }
      } catch (error) {
        console.error("Error during login:", error);
        failed();
      }
    }
  }

  const handleForgotPassword = () => {
    setShowModal(true);
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://dummy.digilateral.com/api/auth/forget-password-user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ MRID: forgotMRID }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Password reset email sent successfully
        toast.success("Check Your Email");
        setShowModal(false); // Close the modal after successful submission
      } else {
        // Error occurred
        toast.error("Enter UserID");
      }
    } catch (error) {
      console.error("Error sending forgot password request:", error);
      // Handle the error appropriately
      toast.error("Enter UserID");
    }
  };

  useEffect(() => {
    Modal.setAppElement("#root"); // Set the root element of your app
  }, []);

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    };
  }, []);

  return (
    <>
      {/* new login UI  */}

      <div class="flex items-center min-h-screen p-4  lg:justify-center">
        <div class="flex flex-col overflow-hidden w-screen  bg-white rounded-md    md:max-w-screen-md">
          <div class="p-4 py-6 text-white   md:flex md:flex-col md:items-center md:justify-evenly">
            <div class="  tracking-wider text-center ">
              <a
                href="#"
                className="text-[26px] text-black font-bold no-underline"
              >
                iCreate Video
              </a>
              <p className="text-[12px] text-black">
                Welcome ! Please login to your account
              </p>
            </div>
          </div>
          <div class="p-5 bg-white md:flex-1">
            <form action="#" class="flex flex-col space-y-5">
              <div class="flex flex-col space-y-1">
                <label
                  for="email"
                  class=" font-semibold text-gray-500 text-[12px] mb-[15px]"
                >
                  UserID<span className="text-red-700">*</span>
                </label>
                <input
                  type="text"
                  id="email"
                  autofocus
                  onChange={(e) => {
                    setMRID(e.target.value);
                  }}
                  class="px-4 py-2  transition duration-300 border border-[#ef8018] rounded-full focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                />
              </div>
              <div class="flex flex-col space-y-1">
                <div class="flex items-center justify-between">
                  <label
                    for="password"
                    class="text-[12px] text-gray-500 font-semibold  mb-[15px]"
                  >
                    Password<span className="text-red-700">*</span>
                  </label>
                </div>
                <input
                  type="password"
                  id="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  class="px-4 py-2  transition duration-300 border border-[#ef8018] rounded-full focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                />
                <div className="flex justify-end">
                  <a
                    href="#"
                    onClick={handleForgotPassword}
                    class="text-sm text-blue-600 hover:underline focus:text-blue-800"
                  >
                    Forgot Password?
                  </a>
                </div>

                <Modal
                  isOpen={showModal}
                  onRequestClose={() => setShowModal(false)}
                  contentLabel="Forgot Password Modal"
                  className="custom-modal"
                  overlayClassName="custom-overlay"
                >
                  <button
                    className="close-button"
                    onClick={() => setShowModal(false)}
                  >
                    &times;
                  </button>

                  <label htmlFor="forgotMRID" className="text-center">
                    Enter UserID:
                  </label>
                  <input
                    type="text"
                    id="forgotMRID"
                    onChange={(e) => setForgotMRID(e.target.value)}
                    className="form-control"
                  />
                  <div className="flex flex-col justify-center items-center">
                    <button
                      onClick={handleForgotPasswordSubmit}
                      className="w-[150px] px-4 py-2 m-[20px] text-[16px]  rounded-full  transition-colors duration-300  bg-[#ef8018] shadow  focus:outline-none focus:ring-blue-200 focus:ring-4"
                    >
                      Submit
                    </button>
                  </div>
                </Modal>
              </div>

              <div class="flex flex-col space-y-5">
                <div class="flex flex-col space-y-4">
                  <div className="flex justify-center items-center pt-[40px]">
                    <button
                      type="submit"
                      onClick={Submit}
                      class="w-[150px] px-4 py-2 text-[16px]  rounded-full  transition-colors duration-300  bg-[#ef8018] shadow  focus:outline-none focus:ring-blue-200 focus:ring-4"
                    >
                      Login
                    </button>
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

