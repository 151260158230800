//Template1  = Video Happy Doctor Day

import React, { useRef, useState, useEffect } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import { useParams, NavLink, useNavigate } from "react-router-dom";

import Sidebar from "./Sidebar/Sidebar";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Template1.css";
// import Image1 from "../components/assets/doctorimg.jpg";
import Image3 from "../components/assets/Grow.jpg";
import { Oval } from "react-loader-spinner";
import { SpaceCompactItemContext } from "antd/es/space/Compact";

const Grow = () => {
  const videoRef = useRef(null);
  const { videoname, MRID, name } = useParams();
  const EmailId = localStorage.getItem("EmailId");
  const ObjID = localStorage.getItem("mrID");
  // console.log(name);
  // console.log(videoname);
  const [showWatermark, setShowWatermark] = useState(false);
  const [binaryVideoData, setBinaryVideoData] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processTime, setProcessTime] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [videoFile, setVideoFile] = useState("");
  const [videoDuration, setVideoDuration] = useState(null);
  const [doctorName, setDoctorName] = useState("");
  const [hospital1, sethospital1] = useState("");
  const [hospital2, sethospital2] = useState("");
  const [topic, settopic] = useState("");
  const [topicTimeIn, settopicTimeIn] = useState("");
  const [topicTimeOut, settopicTimeOut] = useState("");
  const [subtopic1, setsubtopic1] = useState("");
  const [subtopic1TimeIn, setsubtopic1TimeIn] = useState("");
  const [subtopic1TimeOut, setsubtopic1TimeOut] = useState("");
  const [subtopic2, setsubtopic2] = useState("");
  const [subtopic2TimeIn, setsubtopic2TimeIn] = useState("");
  const [subtopic2TimeOut, setsubtopic2TimeOut] = useState("");

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);


  const [showData, setShowData] = useState(false)

  




  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const notify = () =>
    toast.success("Doctor Image Added!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const styles = {
    wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "60vh",
    },
    loaderContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80px", // Match the Oval loader's size
      height: "80px", // Match the Oval loader's size
    },
    text: {
      position: "absolute",
      margin: 0,
      fontSize: "1em", // Adjust font size as needed
      color: "white", // Change text color if needed
    },
  };

  const navigate = useNavigate();

  const failed = () => {
    toast.error("Please Check the Fields or Add Video!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const charLimit = 19;
  const charLimit2 = 47;
  const charLimit3 = 47;
  const charLimit4 = 39;
  const charLimit5 = 47;
  const charLimit6 = 47;

  const handleInputTextChange1 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit) {
      setDoctorName(e.target.value);
    }
  };

  const handleInputTextChange2 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit2) {
      sethospital1(e.target.value);
    }
  };

  const handleInputTextChange3 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit3) {
      sethospital2(e.target.value);
    }
  };

  const handleInputTextChange4 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit4) {
      settopic(e.target.value);
    }
  };

  const handleInputTextChange5 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit5) {
      setsubtopic1(e.target.value);
    }
  };

  const handleInputTextChange6 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit6) {
      setsubtopic2(e.target.value);
    }
  };

  let timeoutId; // For debouncing

  // Topic TimeIn handler with debounce
  const handleInputTextChange7 = (e) => {
    let input = e.target.value;

    // Use regular expression to allow only digits
    input = input.replace(/\D/g, "");

    const timeInSeconds = Math.max(0, parseInt(input, 10) || 0);

    // Set the cleaned input state
    settopicTimeIn(timeInSeconds);
  };

  // Topic TimeOut handler with debounce
  const handleInputTextChange8 = (e) => {
    let input = e.target.value;

    // Use regular expression to allow only digits
    input = input.replace(/\D/g, "");

    const timeInSeconds = Math.max(0, parseInt(input, 10) || 0);

    // Set the cleaned input state
    settopicTimeOut(timeInSeconds);
  };

  // // SubTopic1 TimeIn handler (validate on blur)
  // const handleInputTextChange9 = (e) => {
  //   const timeInSeconds = Math.max(0, parseInt(e.target.value, 10)); // Ensure only positive numbers

  //   if (subtopic1TimeIn < topicTimeIn || subtopic1TimeIn > topicTimeOut) {
  //     toast.error(`SubTopic1 TimeIn must be between Topic TimeIn (${topicTimeIn} seconds) and Topic TimeOut (${topicTimeOut} seconds).`);
  //   }
  //   // Just update state, no validation yet
  //   setsubtopic1TimeIn(timeInSeconds);
  // };

  // // SubTopic1 TimeOut handler (same logic)
  // const handleInputTextChange10 = (e) => {
  //   const timeInSeconds = Math.max(0, parseInt(e.target.value, 10)); // Ensure only positive numbers

  //   if (subtopic1TimeOut < topicTimeIn || subtopic1TimeOut > topicTimeOut) {
  //     toast.error(`SubTopic1 TimeOut must be between Topic TimeIn (${topicTimeIn} seconds) and Topic TimeOut (${topicTimeOut} seconds).`);
  //   }
  //   // Just update state, no validation yet
  //   setsubtopic1TimeOut(timeInSeconds);
  // };

  // Debounced validation handler for SubTopic1 TimeIn and TimeOut
  let debounceSubTopic1Timeout; // For debouncing both handlers
  let debounceSubTopic1Timein;
  // SubTopic1 TimeIn handler with debounce
  const handleInputTextChange9 = (e) => {
    let input = e.target.value;

    input = input.replace(/\D/g, "");

    const timeInSeconds = Math.max(0, parseInt(input, 10) || 0);
    // Update the value immediately
    setsubtopic1TimeIn(timeInSeconds);
  };

  // SubTopic1 TimeOut handler with debounce
  const handleInputTextChange10 = (e) => {
    let input = e.target.value;
    input = input.replace(/\D/g, "");

    const timeInSeconds = Math.max(0, parseInt(input, 10) || 0);

    setsubtopic1TimeOut(timeInSeconds);
  };



  const handleInputTextChange11 = (e) => {
    let input = e.target.value;

    input = input.replace(/\D/g, "");

    const timeInSeconds = Math.max(0, parseInt(input, 10) || 0);
    // Update the value immediately
    setsubtopic2TimeIn(timeInSeconds);
  };

  // SubTopic1 TimeOut handler with debounce
  const handleInputTextChange12 = (e) => {
    let input = e.target.value;
    input = input.replace(/\D/g, "");

    const timeInSeconds = Math.max(0, parseInt(input, 10) || 0);

    setsubtopic2TimeOut(timeInSeconds);
  };


  // // Debounced validation handler for SubTopic1 TimeIn and TimeOut
  // let debounceSubTopic2Timeout; // For debouncing both handlers
  // let debounceSubTopic2Timein;
  // // SubTopic1 TimeIn handler with debounce
  // const handleInputTextChange11 = (e) => {
  //   const input = e.target.value;

  //   // // Regular expression to check for valid numbers only (no letters or special characters)
  //   // const validNumberPattern = /^\d+$/;

  //   // // Check if the input is a valid number
  //   // if (!validNumberPattern.test(input)) {
  //   //   // toast.error("Only numbers are allowed.");
  //   //   return;
  //   // }

  //   const timeInSeconds = Math.max(0, parseInt(input, 10)); // Ensure only positive numbers
  //   console.log("SubTopic2 TimeIn:", timeInSeconds);
  //   // console.log("SubTopic1 Time In evalue",e.target.value);

  //   // Update the value immediately
  //   setsubtopic2TimeIn(input);

  //   // Clear previous debounce timer
  //   clearTimeout(debounceSubTopic2Timein);

  //   console.log("Topic Time IN", topicTimeIn);

  //   if (input && subtopic2TimeOut) {
  //     // Set a new debounce timer
  //     setTimeout(() => {
  //       // Check if the value falls outside Topic TimeIn and TimeOut range
  //       if (subtopic2TimeIn < topicTimeIn) {
  //         //console.log("Topic Time Com",topicTimeIn)
  //         toast.error(
  //           `SubTopic2 TimeIn must be between Topic TimeIn (${topicTimeIn} seconds) and Topic TimeOut (${topicTimeOut} seconds).`
  //         );
  //       } else if (subtopic2TimeIn > subtopic2TimeOut) {
  //         toast.error(
  //           `SubTopic2 TimeIn cannot be greater than SubTopic2 TimeOut.`
  //         );
  //       }
  //     }, 2000); // Debounce delay of 500ms (adjust this as needed)
  //   }
  // };

  // // SubTopic1 TimeOut handler with debounce
  // const handleInputTextChange12 = (e) => {
  //   const input = e.target.value;

  //   const timeInSeconds = input ? Math.max(0, parseInt(input, 10)) : 0; // Ensure only positive numbers
  //   console.log("SubTopic2 TimeOut:", timeInSeconds);

  //   // Update the value immediately
  //   setsubtopic2TimeOut(input);

  //   // Clear previous debounce timer
  //   clearTimeout(debounceSubTopic2Timeout);

  //   if (
  //     subtopic2TimeIn !== null &&
  //     subtopic2TimeIn !== undefined &&
  //     subtopic2TimeOut
  //   ) {
  //     setTimeout(() => {
  //       // Check if the value falls outside Topic TimeIn and TimeOut range
  //       if (timeInSeconds > topicTimeOut) {
  //         toast.error(
  //           `SubTopic2 TimeOut must be between Topic TimeIn (${topicTimeIn} seconds) and Topic TimeOut (${topicTimeOut} seconds).`
  //         );
  //         setsubtopic2TimeOut("");
  //       } else if (subtopic2TimeIn > subtopic2TimeOut) {
  //         // console.log("SubTopic1 Time In ",subtopic1TimeIn);
  //         // console.log("SubTopi Time Out",subtopic1TimeOut);
  //         toast.error(
  //           `SubTopic2 TimeOut cannot be less than SubTopic2 TimeIn.`
  //         );
  //         setsubtopic2TimeOut("");
  //       }
  //     }, 2000); // Debounce delay of 500ms (adjust this as needed)
  //   }
  // };

  // const handleInputTextChange11 = (e) => {
  //   const timeInSeconds = Math.max(0, parseInt(e.target.value, 10)); // Ensures only positive numbers
  //   setsubtopic2TimeIn(timeInSeconds);
  // };

  // const handleInputTextChange12 = (e) => {
  //   const timeInSeconds = Math.max(0, parseInt(e.target.value, 10)); // Ensures only positive numbers
  //   setsubtopic2TimeOut(timeInSeconds);
  // };

  const remainingCharacters1 = Math.max(0, charLimit - doctorName.length);
  const remainingCharacters2 = Math.max(0, charLimit2 - hospital1.length);
  const remainingCharacters3 = Math.max(0, charLimit3 - hospital2.length);
  const remainingCharacters4 = Math.max(0, charLimit4 - topic.length);
  const remainingCharacters5 = Math.max(0, charLimit5 - subtopic1.length);
  const remainingCharacters6 = Math.max(0, charLimit6 - subtopic2.length);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const incrementProgress = (setter, start, end, interval) => {
    let progress = start;
    const step = 5; // Increment by 5%
    const increment = () => {
      progress += step;
      if (progress >= end) {
        progress = end;
      }
      setter(progress);
      if (progress < end) {
        setTimeout(increment, interval);
      }
    };
    increment();
  };

  
  const handleDownloadClick = async () => {
    // Check if the required fields are provided
    if (!doctorName) {
      toast.error("Please enter a Doctor Name.");
      return;
    }

    if (!hospital1) {
      toast.error("Please enter a Hospital1 Name.");
      return;
    }

    if (!hospital2) {
      toast.error("Please enter a Hospital2 Name.");
      return;
    }

    if (!topic) {
      toast.error("Please enter a Topic");
      return;
    }

    if (!subtopic1) {
      toast.error("Please enter a Subtopic1");
      return;
    }

    if (!subtopic2) {
      toast.error("Please enter a Subtopic2");
      return;
    }

    if (!videoFile) {
      toast.error("Please select a video file.");
      return;
    }

    if (!topicTimeIn) {
      toast.error("Please enter a Topic Time In");
      return;
    }

    if (!topicTimeOut) {
      toast.error("Please enter a Topic Time Out");
      return;
    }

    if (!subtopic1TimeIn) {
      toast.error("Please select a Subtopic1 Time In.");
      return;
    }

    if (!subtopic1TimeOut) {
      toast.error("Please select a Subtopic1 Time Out.");
      return;
    }

    if (!subtopic2TimeIn) {
      toast.error("Please select a Subtopic2 Time In.");
      return;
    }

    if (!subtopic2TimeOut) {
      toast.error("Please select a Subtopic2 Time Out.");
      return;
    }

  

    // Show the success toast
    toast.success("You will receive the video on your email shortly.");

    // Delay the navigation to ensure the toast is shown
    setTimeout(() => {
      navigate(`/home/${MRID}`);
    }, 5000); // Adjust the delay time as needed

    const formData = new FormData();
    formData.append("doctorName", doctorName);
    formData.append("hospital1", hospital1);
    formData.append("hospital2", hospital2);
    formData.append("topic", topic);
    formData.append("topicTimeIn", topicTimeIn);
    formData.append("topicTimeOut", topicTimeOut);
    formData.append("subtopic1", subtopic1);
    formData.append("subtopic1TimeIn", subtopic1TimeIn);
    formData.append("subtopic1TimeOut", subtopic1TimeOut);
    formData.append("subtopic2", subtopic2);
    formData.append("subtopic2TimeIn", subtopic2TimeIn);
    formData.append("subtopic2TimeOut", subtopic2TimeOut);
    formData.append("fileName", videoFile);
    formData.append("emailId", EmailId);

    console.log("formdata", [...formData]);

    try {
      setDownloadProgress(0);
      setIsProcessing(true);
      setLoading(true);

      // Make the API request to process the video
      const response = await axios.post(
        `https://dummy.digilateral.com/api/auth/process-grow/${MRID}/${ObjID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob", // Optional if the response needs to be downloaded
        }
      );

      if (response.status !== 200) {
        throw new Error(`Server responded with status: ${response.status}`);
      }

      // Set progress to 100% on successful response
      setDownloadProgress(100);

     
    } catch (error) {
      // Handle errors during the request
      setLoading(false);
      setDownloadProgress(0);
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
      setLoading(false);
    }
  };

  // const handleDownloadClick = async () => {
  //   if (!videoFile) {
  //     toast.error("Please select a video file.");
  //     return;
  //   }

  //   if (!textName) {
  //     toast.error("Please enter a text name.");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("textName", textName);
  //   formData.append("videoFile", videoFile);

  //   try {
  //     setDownloadProgress(0);
  //     setIsProcessing(true);
  //     setLoading(true);
  //     setModalIsOpen(true);

  //     // const startTime = new Date().getTime();
  //     // const maxTime = 16 * 60 * 1000;
  //     // const progressUpdateInterval = 7000;

  //     // const interval = setInterval(() => {
  //     //   const elapsedTime = new Date().getTime() - startTime;
  //     //   const percentage = Math.min((elapsedTime / maxTime) * 100, 100);

  //     //   // Ensure percentage does not exceed 90%
  //     //   if (percentage > 90) {
  //     //     percentage = 90;
  //     //   }
  //     //   setDownloadProgress(Math.floor(percentage));
  //     // }, progressUpdateInterval);

  //     const startTime = new Date().getTime();
  //     const maxTime = 16 * 60 * 1000; // 16 minutes in milliseconds
  //     let currentPercentage = 0;

  //     // First interval for 5% increments up to 70%
  //     const interval1 = setInterval(() => {
  //       currentPercentage += 5;
  //       if (currentPercentage >= 70) {
  //         currentPercentage = 70;
  //         clearInterval(interval1);
  //         // Start second interval for 2% increments up to 90%
  //         const interval2 = setInterval(() => {
  //           currentPercentage += 2;
  //           if (currentPercentage >= 90) {
  //             currentPercentage = 90;
  //             clearInterval(interval2);
  //           }
  //           setDownloadProgress(currentPercentage);
  //         }, 4000); // 4 seconds
  //       }
  //       setDownloadProgress(currentPercentage);
  //     }, 15000); // 7 seconds

  //     const response = await axios.post(
  //       `https://dummy.digilateral.com/api/auth/process-video/${MRID}`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //         responseType: "blob",
  //       }
  //     );

  //     if (response.status !== 200) {
  //       setLoading(false);
  //       navigate(`/home/${MRID}`);
  //       throw new Error(`Server responded with status: ${response.status}`);
  //     }

  //     const endTime = new Date().getTime();
  //     const processingTime = ((endTime - startTime) / 1000).toFixed(2); // Convert to seconds and round
  //     console.log(`Processing time: ${processingTime} seconds`);
  //     setProcessTime(processingTime);
  //     setDownloadProgress(100); // Set progress to 100%

  //     setTimeout(() => {
  //       navigate(`/home/${MRID}`);
  //       setLoading(false);
  //     }, 1400);

  //     const blob = new Blob([response.data], { type: response.data.type });
  //     const fileSizeMB = (blob.size / (1024 * 1024)).toFixed(2);
  //     console.log(`File size: ${fileSizeMB} MB`);
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `Dr. ${textName}.mp4`;
  //     a.click();
  //     URL.revokeObjectURL(url);

  //     // Send usage data
  //     // const userData = await axios.post(
  //     //   `https://dummy.digilateral.com/api/auth/submitUsage/${MRID}`,
  //     //   {
  //     //     type: "videocard",
  //     //     doctorName: textName,
  //     //     videoname: name,
  //     //     fileName: `Dr. ${textName}.mp4`,
  //     //     processTime: `${processingTime}s`,
  //     //     MRID: MRID,
  //     //     MBSIZE: fileSizeMB,
  //     //     status: response.status === 200 ? "success" : "failed",
  //     //   }
  //     // );
  //   } catch (error) {
  //     setLoading(false);
  //     setDownloadProgress(0); // Optionally reset progress to 0
  //     setTimeout(() => {
  //       navigate(`/home/${MRID}`);
  //       setLoading(false);
  //     }, 1400);
  //     toast.error(`Error: ${error.message}`);
  //   } finally {
  //     setIsProcessing(false);
  //   }
  // };

  const handleVideoChange = async (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      const videoFile = selectedFiles[0];

      const video = document.createElement("video");

      video.onloadedmetadata = () => {
        const duration = video.duration;
        setVideoDuration(duration);

        // Check if the video duration is between 60 and 180 seconds
        if (duration >= 60 && duration <= 180) {
          setVideoFile(videoFile);
          //console.log('videofile',videoFile)
          setShowData(true)
          console.log("Video selected:", videoFile);
        } else {
          toast.error(
            "Please select a video that is between 60 and 180 seconds long."
          );
          setVideoDuration('')
          setShowData(false)
          // Reset the file input value to clear the selection
          e.target.value = null;
        }

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(video.src);
      };

      // Handle the case where the metadata loading fails
      video.onerror = () => {
        alert("Error loading the video file. Please try a different file.");
        e.target.value = null;
      };

      // Set the src to the selected video file to trigger metadata loading
      video.src = URL.createObjectURL(videoFile);
    }
  };

  const handleVideoChange1 = async (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      const videoFile = selectedFiles[0];

      const video = document.createElement("video");

      video.onloadedmetadata = () => {
        // Check if the duration is exactly 10 seconds (or within a small tolerance, if needed)
        const duration = video.duration;
        // console.log('duration',duration)

        if (Math.abs(duration - 92.2) < 0.1) {
          // A tolerance of 0.1 seconds can be used for precision issues
          setVideoFile(videoFile);
          console.log("Video selected:", videoFile);
        } else {
          alert("Please select a video that is exactly 92 seconds long.");
          // Reset the file input value to clear the selection
          e.target.value = null;
        }

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(video.src);
      };

      // Handle the case where the metadata loading fails
      video.onerror = () => {
        alert("Error loading the video file. Please try a different file.");
        e.target.value = null;
      };

      // Set the src to the selected video file to trigger metadata loading
      video.src = URL.createObjectURL(videoFile);
    }
  };

  // Fetch the binary video data from the server
  // useEffect(() => {
  //   axios
  //     .get(`https://dummy.digilateral.com/${videoname}`, {
  //       responseType: "arraybuffer",
  //     })
  //     .then((res) => {
  //       // Create a Blob from the binary data
  //       const blob = new Blob([res.data], { type: "video/mp4" });
  //       setBinaryVideoData(blob);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  // Update the video source when binary data changes
  // useEffect(() => {
  //   if (binaryVideoData) {
  //     // Create a URL for the Blob
  //     const videoURL = URL.createObjectURL(binaryVideoData);

  //     // Set the video source to the created URL
  //     const videoPlayer = videoRef.current;
  //     if (videoPlayer) {
  //       videoPlayer.src = videoURL;
  //     }
  //   }
  // }, [binaryVideoData]);

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    };
  }, []);

  return (
    <>
      <div className="flex justify-center items-center flex-col overflow-x-hidden ">
        <div className="w-[100vw]">
          <Sidebar />
        </div>

        <div className="absolute top-[34px] w-[335px]  sm:w-[650px] lg:w-[700px] xl:left-[450px] lg:left-[150px] sm:left-[55px]   ">
          <div className=" w-full">
            <div className="flex flex-row justify-between items-center relative top-[-8px] ">
              <NavLink
                className="p-5 flex relative left-[-36px] "
                to={`/home/${MRID}`}
              >
                <div
                  style={{ backgroundColor: "#F58420", color: "white" }}
                  className="p-[12px] drop-shadow-lg    h-10 w-10   rounded-full relative  top-[-4px] left-[17px] sm:left-[20px]  "
                >
                  <IoMdArrowRoundBack />
                </div>
              </NavLink>
              <div className="text-black text-[20px] font-bold relative left-[-120px] lg:left-[-320px] sm:left-[-270px] top-[-2px]  ">
                <p>Grow</p>
              </div>
            </div>
            <div className="font-bold text-[11px] top-[-34px] relative left-[105px] sm:left-[251px] w-[250px]   sm:text-center   text-[rgba(158,156,158,1)]">
              <p></p>
            </div>
          </div>

          <div className="">
            <div className="w-[335px]  relative sm:w-auto top-[-30px] border">
              <div>
                <div>
                  <img width="900" src={Image3} alt="Image1" />
                </div>

                <br />
                

                <div
                  className="relative left-[10px] w-full  mb-[10px] sm:top-[15px] "
                  style={{ display: "inline-block", marginRight: "10px" }}
                >
                  <input
                    type="file"
                    accept="video/mp4"
                    onChange={(e) => handleVideoChange(e)}
                  />

                  <p
                    class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                    id="file_input_help"
                  >
                    {" "}
                    MP4  
                    {showData ? (<span> ( Duration = {Math.floor(videoDuration)} s ) </span> ) : ''}
                  </p>

                  {/* <label className="relative sm:bottom-[15px] bottom-[5px] text-gray-500">Duration = {videoDuration}</label> */}
                </div>

                <div className="w-full relative   ">
                  <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left ">
                    <label className="">Doctor Name*</label>
                  </div>

                  <input
                    type="text"
                    className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                    value={doctorName}
                    onChange={handleInputTextChange1}
                    placeholder="Enter Doctor Name(without Dr. prefix)"
                  />

                  <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                    {remainingCharacters1} of {charLimit}
                  </div>

                  <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                    <label className="">Hospital1*</label>
                  </div>

                  <input
                    type="text"
                    className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                    value={hospital1}
                    onChange={handleInputTextChange2}
                    placeholder="Enter Hospital Info"
                  />

                  <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                    {remainingCharacters2} of {charLimit2}
                  </div>

                  <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                    <label className="">Hospital2*</label>
                  </div>

                  <input
                    type="text"
                    className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                    value={hospital2}
                    onChange={handleInputTextChange3}
                    placeholder="Enter Hospital Info"
                  />

                  <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                    {remainingCharacters3} of {charLimit3}
                  </div>

                  <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                    <label className="">Topic*</label>
                  </div>

                  <input
                    type="text"
                    className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                    value={topic}
                    onChange={handleInputTextChange4}
                    placeholder="Enter Topic"
                  />

                  <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                    {remainingCharacters4} of {charLimit4}
                  </div>

                  <div className="flex items-center space-x-4 relative sm:left-[-8px]">
                    {/* Topic TimeIn */}
                    <div className="w-[45%] text-left relative sm:left-[20px]">
                      <label>Topic TimeIn*</label>
                      <input
                        type="text"
                        min="0"
                        className="w-full mb-[10px] h-[40px] border-2 rounded-xl p-[10px]"
                        value={topicTimeIn}
                        onChange={handleInputTextChange7}
                        placeholder="Enter TimeIn (seconds)"
                        step="1"
                      />
                      {topicTimeIn > videoDuration ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Topic Timein Less than video Duration
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* Topic TimeOut */}
                    <div className="w-[45%] text-left relative sm:left-[20px]">
                      <label>Topic TimeOut*</label>
                      <input
                        type="text"
                        min="0"
                        className="w-full mb-[10px] h-[40px] border-2 rounded-xl p-[10px]"
                        value={topicTimeOut}
                        onChange={handleInputTextChange8}
                        placeholder="Enter TimeOut (seconds)"
                        step="1"
                      />
                      {topicTimeOut !== "" && topicTimeOut < topicTimeIn && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Topic TimeOut must be greater than Topic TimeIn.
                        </span>
                      )}

                      {topicTimeOut !== "" && topicTimeOut > videoDuration && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Topic TimeOut cannot exceed the video duration of{" "}
                          {videoDuration} seconds.
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left sm:top-[10px]">
                    <label className="">Subtopic1*</label>
                  </div>

                  <input
                    type="text"
                    className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[16px] sm:left-[10px] "
                    value={subtopic1}
                    onChange={handleInputTextChange5}
                    placeholder="Enter Subtopic"
                  />

                  <div className="text-sm text-gray-500 flex justify-end mr-[10px] relative sm:top-[10px]">
                    {remainingCharacters5} of {charLimit5}
                  </div>

                  <div className="flex items-center space-x-4 relative sm:left-[-8px]">
                    <div className="w-[45%] text-left relative sm:left-[20px]">
                      <label>Subtopic1 TimeIn*</label>
                      <input
                        type="text"
                        min="0"
                        className="w-full mb-[10px] h-[40px] border-2 rounded-xl p-[10px]"
                        value={subtopic1TimeIn}
                        onChange={handleInputTextChange9}
                        placeholder="Enter TimeIn (seconds)"
                        step="1"
                      />
                      {subtopic1TimeIn !== "" &&
                      subtopic1TimeIn > topicTimeOut ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {`SubTopic1 TimeOut must be between Topic TimeIn (${topicTimeIn} seconds) and Topic TimeOut (${topicTimeOut} seconds).`}
                        </span>
                      ) : (
                        ""
                      )}

                      {subtopic1TimeIn !== "" &&
                      subtopic1TimeIn < topicTimeIn ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {`SubTopic1 TimeOut must be between Topic TimeIn (${topicTimeIn} seconds) and Topic TimeOut (${topicTimeOut} seconds).`}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    

                    <div className="w-[45%] text-left relative sm:left-[20px]">
                      <label>Subtopic1 TimeOut*</label>
                      <input
                        type="text"
                        min="0"
                        className="w-full mb-[10px] h-[40px] border-2 rounded-xl p-[10px]"
                        value={subtopic1TimeOut}
                        onChange={handleInputTextChange10}
                        placeholder="Enter TimeOut (seconds)"
                        step="1"
                      />

                    {subtopic1TimeOut !== "" &&
                      subtopic1TimeOut > topicTimeOut ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {`SubTopic1 TimeOut must be between Topic TimeIn (${topicTimeIn} seconds) and Topic TimeOut (${topicTimeOut} seconds).`}
                        </span>
                      ) : (
                        ""
                      )}

                      {subtopic1TimeOut !== "" &&
                      subtopic1TimeOut < subtopic1TimeIn ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Subtopic1 TimeOut Less than Subtopic1 Timein
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left sm:top-[10px]">
                    <label className="">Subtopic2*</label>
                  </div>

                  <input
                    type="text"
                    className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[20px] sm:left-[10px] "
                    value={subtopic2}
                    onChange={handleInputTextChange6}
                    placeholder="Enter Subtopic"
                  />

                  <div className="text-sm text-gray-500 flex justify-end mr-[10px] relative sm:top-[10px]">
                    {remainingCharacters6} of {charLimit6}
                  </div>

                  <div className="flex items-center space-x-4 relative sm:left-[-8px]">
                    <div className="w-[45%] text-left relative sm:left-[20px]">
                      <label>Subtopic2 TimeIn*</label>
                      <input
                        type="text"
                        min="0"
                        className="w-full mb-[10px] h-[40px] border-2 rounded-xl p-[10px]"
                        value={subtopic2TimeIn}
                        onChange={handleInputTextChange11}
                        placeholder="Enter TimeIn (seconds)"
                        step="1"
                      />

                    {subtopic2TimeIn !== "" &&
                      subtopic2TimeIn > topicTimeOut ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {`SubTopic2 TimeOut must be between Topic TimeIn (${topicTimeIn} seconds) and Topic TimeOut (${topicTimeOut} seconds).`}
                        </span>
                      ) : (
                        ""
                      )}

                      {subtopic2TimeIn !== "" &&
                      subtopic2TimeIn < topicTimeIn ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {`SubTopic2 TimeOut must be between Topic TimeIn (${topicTimeIn} seconds) and Topic TimeOut (${topicTimeOut} seconds).`}
                        </span>
                      ) : (
                        ""
                      )}

                    {subtopic2TimeIn !== "" &&
                       subtopic2TimeIn <= subtopic1TimeOut ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {`SubTopic2 TimeIn must be Outside the range of SubTopic1 TimeIn (${subtopic1TimeIn} seconds) and SubTopic1 TimeOut (${subtopic1TimeOut} seconds).`}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="w-[45%] text-left relative sm:left-[20px]">
                      <label>Subtopic2 TimeOut*</label>
                      <input
                        type="text"
                        min="0"
                        className="w-full mb-[10px] h-[40px] border-2 rounded-xl p-[10px]"
                        value={subtopic2TimeOut}
                        onChange={handleInputTextChange12}
                        placeholder="Enter TimeOut (seconds)"
                        step="1"
                      />

                    {subtopic2TimeOut !== "" &&
                      subtopic2TimeOut > topicTimeOut ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {`SubTopic2 TimeOut must be between Topic TimeIn (${topicTimeIn} seconds) and Topic TimeOut (${topicTimeOut} seconds).`}
                        </span>
                      ) : (
                        ""
                      )}

                      {/* {subtopic2TimeOut !== "" &&
                      subtopic2TimeOut < topicTimeIn ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {`SubTopic2 TimeOut must be between Topic TimeIn (${topicTimeIn} seconds) and Topic TimeOut (${topicTimeOut} seconds).`}
                        </span>
                      ) : (
                        ""
                      )} */}

                    {subtopic2TimeOut !== "" &&
                      subtopic2TimeOut < subtopic2TimeIn ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Subtopic2 TimeOut Less than Subtopic2 Timein
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {/* <div
                    className="relative left-[10px] w-full  mb-[10px] sm:top-[15px]"
                    style={{ display: "inline-block", marginRight: "10px" }}
                  >
                    <input
                      type="file"
                      accept="video/mp4"
                      onChange={(e) => handleVideoChange(e)}
                    />

                    <p
                      class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                      id="file_input_help"
                    >
                      {" "}
                      MP4
                    </p>
                  </div> */}

                  <br />
                  <ToastContainer />
                  <button
  onClick={handleDownloadClick}
  disabled={
    isProcessing || // Already processing
    topicTimeIn > videoDuration || // Topic TimeIn is greater than video duration
    topicTimeOut < topicTimeIn || // Topic TimeOut is less than Topic TimeIn
    topicTimeOut > videoDuration || // Topic TimeOut is greater than video duration
    subtopic1TimeIn < topicTimeIn || // Subtopic1 TimeIn is less than Topic TimeIn
    subtopic1TimeOut > topicTimeOut || // Subtopic1 TimeOut is greater than Topic TimeOut
    subtopic1TimeOut < subtopic1TimeIn || // Subtopic1 TimeOut is less than Subtopic1 TimeIn
    subtopic2TimeIn <= subtopic1TimeOut || // Subtopic2 TimeIn is less than or equal to Subtopic1 TimeOut
    subtopic2TimeOut > topicTimeOut || // Subtopic2 TimeOut is greater than Topic TimeOut
    subtopic2TimeOut < subtopic2TimeIn // Subtopic2 TimeOut is less than Subtopic2 TimeIn
  }
  className={`${
    isProcessing ||
    topicTimeIn > videoDuration ||
    topicTimeOut < topicTimeIn ||
    topicTimeOut > videoDuration ||
    subtopic1TimeIn < topicTimeIn ||
    subtopic1TimeOut > topicTimeOut ||
    subtopic1TimeOut < subtopic1TimeIn ||
    subtopic2TimeIn <= subtopic1TimeOut ||
    subtopic2TimeOut > topicTimeOut ||
    subtopic2TimeOut < subtopic2TimeIn
      ? "bg-gray-400 cursor-not-allowed"
      : "bg-green-500"
  } text-white mt-[20px] rounded-full w-full h-[30px]`}
>
  {isProcessing ? "Processing..." : "Download"}
</button>

                </div>
              </div>

              {/* <div style={styles.wrapper}>
                  <div style={styles.loaderContainer}>
                    <Oval
                      visible={true}
                      height="80"
                      width="80"
                      color="violet"
                      secondaryColor="violet"
                      ariaLabel="oval-loading"
                    />
                    <h4 style={styles.text}>{downloadProgress}%</h4>
                  </div>
                </div> */}
              {loading ? (
                <ReactModal
                  isOpen={modalIsOpen}
                  // onRequestClose={() => setModalIsOpen(false)}
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                    content: {
                      // top: "50%",
                      // left: "53%",
                      // right: "auto",
                      // bottom: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // marginRight: "-4%",
                      marginRight: isSmallScreen ? "0px" : "-4%",
                      // transform: "translate(-50%, -50%)",
                      borderRadius: "10px",
                      // padding: "20px",
                      textAlign: "center",
                      backgroundColor: "transparent",
                      border: "none",
                      boxShadow: "none",
                    },
                  }}
                  contentLabel="Processing Modal"
                >
                  <div style={styles.wrapper}>
                    <div style={styles.loaderContainer}>
                      <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="orange"
                        secondaryColor="orange"
                        ariaLabel="oval-loading"
                      />
                      <h4 style={styles.text}>{downloadProgress}%</h4>
                    </div>
                  </div>
                </ReactModal>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {/* // new Ui  */}
    </>
  );
};

export default Grow;

