//Template1  = Video Happy Doctor Day

import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import { Oval } from "react-loader-spinner";
import Sidebar from "./Sidebar/Sidebar";
import { IoMdArrowRoundBack } from "react-icons/io";
import EpilepsyCrop from "./Crop/EpilepsyCrop";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Template1.css";
import Image4 from "../components/assets/Epilepsy_Thumb.jpg";

const Epilepsy = () => {
  const { videoname, MRID } = useParams();
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [processTime, setProcessTime] = useState("");

  const navigate = useNavigate();

  const EmailId = localStorage.getItem("EmailId");

  const notify = () =>
    toast.success("Doctor Image Added!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const failed = () => {
    toast.error("Please Check the Fields or Add Image!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const downloadcomplete = () =>
    toast.success("Video Processed !", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const styles = {
    wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "60vh",
    },
    loaderContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80px", // Match the Oval loader's size
      height: "80px", // Match the Oval loader's size
    },
    text: {
      position: "absolute",
      margin: 0,
      fontSize: "1em", // Adjust font size as needed
      color: "white", // Change text color if needed
    },
  };

  const charLimit = 19;
  const charLimit2 = 25;

  const charLimitCity = 30;

  const charLimitHospital = 30;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [modalIsOpen1, setModalIsOpen1] = useState(false);

  const [doctorName, setDoctorName] = useState("");
  const [qualification, setQualification] = useState("");
  const [city, setCity] = useState("");
  const [hospital, setHospital] = useState("");
  const [docImage, setDocImg] = useState("");
  const [video1, setVideo1] = useState("");
  const [video2, setVideo2] = useState("");
  const [video3, setVideo3] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //MRID Get form localstorage...
  const mrId = localStorage.getItem("mrID");
  const ObjID = localStorage.getItem("mrID");

  const handleInputTextChange1 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit) {
      setDoctorName(e.target.value);
    }
  };

  const remainingCharacters1 = Math.max(0, charLimit - doctorName.length);

  const handleInputTextChange2 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimitCity) {
      setCity(e.target.value);
    }
  };
  const remainingCharacters2 = Math.max(0, charLimitCity - city.length);

  const handleInputTextChange3 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit2) {
      setQualification(e.target.value);
    }
  };
  const remainingCharacters3 = Math.max(0, charLimit2 - qualification.length);

  const handleInputTextChange4 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimitHospital) {
      setHospital(e.target.value);
    }
  };
  const remainingCharacters4 = Math.max(0, charLimitHospital - hospital.length);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleImageChange = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      const imageFile = selectedFiles[0];
      const img = new Image();

      img.onload = () => {
        // Check if the dimensions are exactly 380x380
        if (img.width === 1080 && img.height === 1080) {
          const reader = new FileReader();
          reader.onload = (event) => {
            setDocImg(imageFile);
          };
          reader.readAsDataURL(imageFile);
        } else {
          toast.error("Please select an image that is exactly 1080x1080 pixels.");
          e.target.value = null;
        }
      };

      // Handle the case where the image loading fails
      img.onerror = () => {
        alert("Error loading the image file. Please try a different file.");
        e.target.value = null;
      };

      // Set the src to the selected image file to trigger the onload event
      img.src = URL.createObjectURL(imageFile);
    }
  };


  

  const handleDownloadClick = async () => {
    if (!doctorName) {
      toast.error("Please Enter Doctor Name");
      return;
    }
  
    if (!qualification) {
      toast.error("Please Enter your Qualification");
      return;
    }
  
    if (!city) {
      toast.error("Please Enter your City");
      return;
    }
  
    if (!docImage) {
      toast.error("Please Select Doctor Image");
      return;
    }
  
   
  
    // Show the toast message
    toast.success("You will receive the video on your email shortly.");
  
    // Delay the navigation to ensure the toast is shown
    setTimeout(() => {
      navigate(`/home/${MRID}`);
    }, 5000); // Adjust the delay time as needed
  
    const formData = new FormData();
    formData.append("doctorName", doctorName);
    formData.append("speciality", qualification);
    formData.append("city", city);
    formData.append("hospital", hospital);
    formData.append("doctorImage", docImage);
    formData.append("emailId", EmailId);
  
    console.log("formdata", [...formData]);
  
    try {
      setDownloadProgress(0);
      setIsProcessing(true);
      setLoading(true);
  
      const response = await axios.post(
        `https://dummy.digilateral.com/api/auth/process-epilepsy/${MRID}/${ObjID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        }
      );
  
      if (response.status !== 200) {
        throw new Error(`Server responded with status: ${response.status}`);
      }
  
      // Handle the response, if necessary, after navigating to the new page
      // For example, you can track the status and update it on the new page
  
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
      setLoading(false);
    }
  };
  

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    };
  }, []);

  return (
    <>
      <div className="flex justify-center items-center flex-col overflow-x-hidden ">
        <div className="w-[100vw]">
          <Sidebar />
        </div>

        <div className="absolute top-[34px] w-[335px]  sm:w-[650px] lg:w-[700px] xl:left-[450px] lg:left-[150px] sm:left-[55px]   ">
          <div className=" w-full">
            <div className="flex flex-row justify-between items-center relative top-[-8px] ">
              <NavLink
                className="p-5 flex relative left-[-36px] "
                to={`/home/${MRID}`}
              >
                <div
                  style={{ backgroundColor: "#F58420", color: "white" }}
                  className="p-[12px] drop-shadow-lg    h-10 w-10   rounded-full relative  top-[-4px] left-[17px] sm:left-[20px]  "
                >
                  <IoMdArrowRoundBack />
                </div>
              </NavLink>

              <div className="text-black text-[20px] font-bold relative left-[-70px] lg:left-[-270px] sm:left-[-270px] top-[-1px]  ">
                <p>Typhoid Monsoon</p>
              </div>
            </div>
            <div className="font-bold text-[11px] top-[-34px] relative left-[105px] sm:left-[251px] w-[250px]   sm:text-center   text-[rgba(158,156,158,1)]">
              <p></p>
            </div>
          </div>

          <div className="">
            <div className="w-[335px] border-2  relative sm:w-auto top-[-30px]">
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Crop Modal"
              >
                <EpilepsyCrop />
                {/* Close icon */}
                <div className="absolute top-[20px] right-0 bg-orange-500 p-[12px] drop-shadow-lg    h-10 w-10   rounded-full ">
                  <button onClick={closeModal} className="">
                    {" "}
                    <FaTimes color={"#fff"} />
                  </button>{" "}
                </div>
              </Modal>

              {/* Video 1 */}

              <div className="flex items-center justify-center bg-gray-100">
                <img width="200" src={Image4} alt="Image1" />
              </div>

              <br />

              <div className="w-full relative   ">
                <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                  <label className="">Doctor Name*</label>
                </div>

                <input
                  type="text"
                  className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                  value={doctorName}
                  onChange={handleInputTextChange1}
                  placeholder="Enter Doctor Name(without Dr. prefix)"
                />

                <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                  {remainingCharacters1} of {charLimit}
                </div>

                <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                  <label className="">Speciality*</label>
                </div>

                <input
                  type="text"
                  className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                  value={qualification}
                  onChange={handleInputTextChange3}
                  placeholder="Enter Speciality"
                />

                <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                  {remainingCharacters3} of {charLimit2}
                </div>

                <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                  <label className="">Hospital*</label>
                </div>

                <input
                  type="text"
                  className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                  value={hospital}
                  onChange={handleInputTextChange4}
                  placeholder="Enter Hospital"
                />

                <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                  {remainingCharacters4} of {charLimitHospital}
                </div>

                <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                  <label className="">City*</label>
                </div>

                <input
                  type="text"
                  className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                  value={city}
                  onChange={handleInputTextChange2}
                  placeholder="Enter City"
                />

                <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                  {remainingCharacters2} of {charLimitCity}
                </div>

                <div className="sm:grid sm:grid-cols-2 sm:mt-4">
                  {" "}
                  {/*sm:ml-[60px] */}
                  <div
                    className="relative left-[10px] w-full  mb-[10px]"
                    style={{ display: "inline-block", marginRight: "10px" }}
                  >
                    <div className="w-[300px]  relative mb-2 mt-2 sm:left-[5px] left-[2px] text-left">
                      <label className="">Select Doctor Image*</label>
                    </div>

                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageChange(e)}
                    />

                    <p
                      class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                      id="file_input_help"
                    >
                      {" "}
                      select PNG, JPG with exactly 1080*1080.
                    </p>
                  </div>
                  
                </div>

                <br />
                <button
                  onClick={openModal}
                  className="bg-[#afaeae] text-black  rounded-full  transition duration-300 w-full h-[30px]"
                >
                  Crop
                </button>

                <ToastContainer />
                <button
                  onClick={handleDownloadClick}
                  className={`${isProcessing ? 'bg-gray-400 text-white mt-[20px] rounded-full  w-full h-[30px]' : "bg-green-500  text-white mt-[20px] rounded-full  w-full h-[30px]"}`}
                  disabled={isProcessing}
                >
                  {isProcessing ? "Generating Your Video Please Wait" : "Download"}
                </button>
              </div>
            </div>

            {/* {loading ? (
              <ReactModal
                isOpen={modalIsOpen1}
                // onRequestClose={() => setModalIsOpen(false)}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  },
                  content: {
                    // top: "50%",
                    // left: "53%",
                    // right: "auto",
                    // bottom: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginRight: "-4%",
                    marginRight: isSmallScreen ? "0px" : "-4%",
                    // transform: "translate(-50%, -50%)",
                    borderRadius: "10px",
                    // padding: "20px",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                  },
                }}
                contentLabel="Processing Modal"
              >
                <div style={styles.wrapper}>
                  <div style={styles.loaderContainer}>
                    <Oval
                      visible={true}
                      height="80"
                      width="80"
                      color="orange"
                      secondaryColor="orange"
                      ariaLabel="oval-loading"
                    />
                    <h4 style={styles.text}>{downloadProgress}%</h4>
                  </div>
                </div>
              </ReactModal>
            ) : (
              ""
            )} */}
          </div>
        </div>
      </div>

      {/* // new Ui  */}
    </>
  );
};

export default Epilepsy;


