/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

// export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
//   const image = await createImage(imageSrc);
//   const canvas = document.createElement("canvas");
//   const ctx = canvas.getContext("2d");

//   const maxSize = Math.max(image.width, image.height);
//   const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

//   // set each dimensions to double largest dimension to allow for a safe area for the
//   // image to rotate in without being clipped by canvas context
//   canvas.width = safeArea;
//   canvas.height = safeArea;

//   // translate canvas context to a central location on image to allow rotating around the center.
//   ctx.translate(safeArea / 2, safeArea / 2);
//   ctx.rotate(getRadianAngle(rotation));
//   ctx.translate(-safeArea / 2, -safeArea / 2);

//   // draw rotated image and store data.
//   ctx.drawImage(
//     image,
//     safeArea / 2 - image.width * 0.5,
//     safeArea / 2 - image.height * 0.5
//   );

//   const data = ctx.getImageData(0, 0, safeArea, safeArea);

//   // set canvas width to final desired crop size - this will clear existing context
//   canvas.width = pixelCrop.width;
//   canvas.height = pixelCrop.height;

//   // paste generated rotate image with correct offsets for x,y crop values.
//   ctx.putImageData(
//     data,
//     0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
//     0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
//   );

//   // As Base64 string
//   // return canvas.toDataURL("image/jpeg");
//   return canvas;
// }

const currentDate = new Date();
const options = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false, // Set to false to use 24-hour format
  timeZone: "Asia/Kolkata",
};

const formattedDate = currentDate
  .toLocaleString("en-IN", options)
  .replace(/[\/,\s:]/g, "_");



// export const generateDownload = async (imageSrc, crop,name) => {
// 	if (!crop || !imageSrc || !name) {
// 		return;
// 	}

// 	const canvas = await getCroppedImg(imageSrc, crop);

// 	canvas.toBlob(
// 		(blob) => {
//             const fileName = `${name}_${formattedDate}.jpeg`;

// 			const previewUrl = window.URL.createObjectURL(blob);

// 			const anchor = document.createElement("a");
// 			anchor.download = fileName;
// 			anchor.href = URL.createObjectURL(blob);
// 			anchor.click();

// 			window.URL.revokeObjectURL(previewUrl);
// 		},
// 		"image/jpeg",
// 		0.66
// 	);
// };

// export const generateDownload = async (imageSrc, crop, name, formattedDate) => {
//     if (!crop || !imageSrc || !name) {
//         return;
//     }

//     const canvas = await getCroppedImg(imageSrc, crop);

//     // Create a new canvas to draw the rounded image with a transparent background
//     const outputCanvas = document.createElement('canvas');
//     const context = outputCanvas.getContext('2d');
//     const size = 400;
//     const radius = size / 2;

//     outputCanvas.width = size;
//     outputCanvas.height = size;

//     // Clear the canvas to ensure transparency
//     context.clearRect(0, 0, size, size);

//     // Draw the rounded rectangle for clipping
//     context.beginPath();
//     context.arc(radius, radius, radius, 0, Math.PI * 2);
//     context.closePath();
//     context.clip();

//     // Draw the cropped image onto the new canvas within the clipped region
//     context.drawImage(canvas, 0, 0, size, size);

//     // Generate the blob and handle download
//     outputCanvas.toBlob(
//         (blob) => {
//             const fileName = `${name}_${Date.now()}.png`; // Use .png to preserve transparency

//             const previewUrl = window.URL.createObjectURL(blob);

//             const anchor = document.createElement("a");
//             anchor.download = fileName;
//             anchor.href = previewUrl;
//             anchor.click();

//             window.URL.revokeObjectURL(previewUrl);
//         },
//         "image/png" // Use PNG format to preserve transparency
//     );
// };




// // Helper function to get the cropped image
// async function getCroppedImg(imageSrc, crop) {
//     return new Promise((resolve) => {
//         const image = new Image();
//         image.src = imageSrc;

//         image.onload = () => {
//             const canvas = document.createElement('canvas');
//             const ctx = canvas.getContext('2d');

//             const scaleX = image.naturalWidth / image.width;
//             const scaleY = image.naturalHeight / image.height;
//             const pixelRatio = window.devicePixelRatio;

//             canvas.width = crop.width * scaleX * pixelRatio;
//             canvas.height = crop.height * scaleY * pixelRatio;

//             ctx.scale(pixelRatio, pixelRatio);
//             ctx.imageSmoothingQuality = 'high';

//             ctx.drawImage(
//                 image,
//                 crop.x * scaleX,
//                 crop.y * scaleY,
//                 crop.width * scaleX,
//                 crop.height * scaleY,
//                 0,
//                 0,
//                 crop.width,
//                 crop.height
//             );

//             resolve(canvas);
//         };
//     });
// }




export const generateDownload = async (imageSrc, crop, name) => {
  if (!crop || !imageSrc || !name) {
      return;
  }

  const canvas = await getCroppedImg(imageSrc, crop);

  // Create a new canvas to resize the cropped image to 380x380
  const outputCanvas = document.createElement('canvas');
  const context = outputCanvas.getContext('2d');
  const size = 390;

  outputCanvas.width = size;
  outputCanvas.height = size;

  // Clear the canvas to ensure transparency
  context.clearRect(0, 0, size, size);

  // Draw the cropped image onto the new canvas with the specified size
  context.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, size, size);

  // Generate the blob and handle download
  outputCanvas.toBlob(
      (blob) => {
          const fileName = `${name}_${formattedDate}.jpeg`;

          const previewUrl = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = previewUrl;
          anchor.click();

          window.URL.revokeObjectURL(previewUrl);
      },
      "image/jpeg",
      0.66
  );
};

// Helper function to get the cropped image
async function getCroppedImg(imageSrc, crop) {
  return new Promise((resolve) => {
      const image = new Image();
      image.src = imageSrc;

      image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const scaleX = image.naturalWidth / image.width;
          const scaleY = image.naturalHeight / image.height;
          const pixelRatio = window.devicePixelRatio;

          canvas.width = crop.width * scaleX * pixelRatio;
          canvas.height = crop.height * scaleY * pixelRatio;

          ctx.scale(pixelRatio, pixelRatio);
          ctx.imageSmoothingQuality = 'high';

          ctx.drawImage(
              image,
              crop.x * scaleX,
              crop.y * scaleY,
              crop.width * scaleX,
              crop.height * scaleY,
              0,
              0,
              crop.width,
              crop.height
          );

          resolve(canvas);
      };
  });
}


