import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import UserSidebar from "./Sidebar/Sidebar";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaTimes } from "react-icons/fa";
import Image1 from "../components/assets/Birthdayimg.jpg";
import { SyncLoader } from "react-spinners";
import CropFun from "./Crop/CropFun";

const Test = () => {
  const videoRef = useRef(null);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  const videoRef4 = useRef(null);
  const videoRef5 = useRef(null);

  const charLimit = 19;

  const navigate = useNavigate();

  const [showWatermark, setShowWatermark] = useState(false);
  const [binaryVideoData, setBinaryVideoData] = useState(null);
  const [watermarkImages, setWatermarkImages] = useState([]);

  // console.log("all images", watermarkImages);
  const [inputText1, setInputText1] = useState("");
  const [inputText2, setInputText2] = useState("");
  const [inputText3, setInputText3] = useState("");
  const [inputText4, setInputText4] = useState("");
  const [inputText5, setInputText5] = useState("");
  const [inputText6, setInputText6] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("group1");

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [groupInputs, setGroupInputs] = useState({
    group1: { fileInputs: Array(4).fill(null) },
    group2: { fileInputs: Array(8).fill(null) },
    group3: { fileInputs: Array(12).fill(null) },
    group4: { fileInputs: Array(16).fill(null) },
    group5: { fileInputs: Array(20).fill(null) },
    group6: { fileInputs: Array(24).fill(null) },
    // Add more groups and specify the number of inputs for each
  });

  const [isLoadingVideo1, setIsLoadingVideo1] = useState(false);

  const notify = () =>
    toast.success("Doctor Image Added!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const downloadcomplete = () =>
    toast.success("Video Processed !", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const failed = () => {
    toast.error("Please Check the Fields or Add Image!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const { videoname, MRID, name } = useParams();

  //MRID Get form localstorage...
  const mrId = localStorage.getItem("mrID");

  //Handle groups according images....
  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
    setIsButtonDisabled(false);
    setWatermarkImages([]);
    setInputText1("");
    setInputText2("");
    setInputText3("");
    setInputText4("");
    setInputText5("");
    setInputText6("");
  };

  //Update the inputText for only 1 video...
  const handleInputTextChange1 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit) {
      setInputText1(e.target.value);
    }
  };

  const remainingCharacters1 = Math.max(0, charLimit - inputText1.length);

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      const loadImage = () =>
        new Promise((resolve) => {
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(file);
        });

      loadImage().then((imageResult) => {
        console.log("Index:", index);
        console.log("Previous watermarkImages:", watermarkImages);

        const updatedInputs = { ...groupInputs };
        if (selectedGroup === "group1") {
          updatedInputs.group1.fileInputs[index] = file;
        } else if (selectedGroup === "group2") {
          updatedInputs.group2.fileInputs[index] = file;
        } else if (selectedGroup === "group3") {
          updatedInputs.group3.fileInputs[index] = file;
        } else if (selectedGroup === "group4") {
          updatedInputs.group4.fileInputs[index] = file;
        } else if (selectedGroup === "group5") {
          updatedInputs.group5.fileInputs[index] = file;
        } else if (selectedGroup === "group6") {
          updatedInputs.group6.fileInputs[index] = file;
        }
        setGroupInputs(updatedInputs);

        Promise.all([imageResult, ...watermarkImages]).then((results) =>
          setWatermarkImages(results.filter(Boolean))
        );
      });
    }
  };

  const addWatermark = () => {
    console.log("Image" + watermarkImages.length);

    let isValid = true;

    if (selectedGroup === "group1" && watermarkImages.length <= 3) {
      failed();
      isValid = false;
    } else if (selectedGroup === "group2" && watermarkImages.length <= 7) {
      failed();
      isValid = false;
    } else if (selectedGroup === "group3" && watermarkImages.length <= 11) {
      failed();
      isValid = false;
    } else if (selectedGroup === "group4" && watermarkImages.length <= 15) {
      failed();
      isValid = false;
    } else if (selectedGroup === "group5" && watermarkImages.length <= 19) {
      failed();
      isValid = false;
    } else if (selectedGroup === "group6" && watermarkImages.length <= 23) {
      failed();
      isValid = false;
    }

    if (isValid) {
      setShowWatermark(true);
      console.log(isButtonDisabled);
      // setButtonText('Image Added');
      setIsButtonDisabled(true);
      // console.log("final",isButtonDisabled);

      // Reset button color after a certain delay (e.g., 2 seconds)
      setTimeout(() => {
        // setIsButtonDisabled(false);
        notify();
        // console.log(isButtonDisabled);
      }, 2000);
    }
  };

  // Handle the User Usage Data....
  const handleUserData = async (inputText, formatTrackedTime, fileSizeMB) => {
    const requestBody = {
      MRID,
      type: "videocard",
      videoname: name,
      fileName: inputText,
      doctorName: inputText,
      processTime: formatTrackedTime,
      MBSIZE: fileSizeMB,
    };

    fetch(` https://dummy.digilateral.com/api/auth/submitUsage/${mrId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.success);
        if (data.success === true) {
          console.log("Data Added");
        } else {
          console.log("Data Failed to Add");
        }
      });
  };

  //Loop the image to store.......
  const watermarkImageObjects = watermarkImages.map((src) => {
    const image = new Image();
    image.src = src;
    return image;
  });

  console.log("Total Images objects :", watermarkImageObjects);

  //Get the time.....
  let downloadStartTime;

  //Download the 1st video with starting 4Images....
  const downloadWithWatermark1 = async () => {
    // console.log("Birthday", watermarkImageObjects);

    // Store the start time when the download button is clicked
    downloadStartTime = performance.now();

    if (
      selectedGroup === "group1" &&
      (inputText1 === "" || isButtonDisabled === false)
    ) {
      failed();
    } else if (
      selectedGroup === "group2" &&
      (inputText2 === "" || inputText1 === "" || isButtonDisabled === false)
    ) {
      failed();
    } else if (
      selectedGroup === "group3" &&
      (inputText3 === "" ||
        inputText2 === "" ||
        inputText1 === "" ||
        isButtonDisabled === false)
    ) {
      failed();
    } else if (
      selectedGroup === "group4" &&
      (inputText4 === "" ||
        inputText3 === "" ||
        inputText2 === "" ||
        inputText1 === "" ||
        isButtonDisabled === false)
    ) {
      failed();
    } else if (
      selectedGroup === "group5" &&
      (inputText5 == "" ||
        inputText4 === "" ||
        inputText3 === "" ||
        inputText2 === "" ||
        inputText1 === "" ||
        isButtonDisabled === false)
    ) {
      failed();
    } else if (
      selectedGroup === "group6" &&
      (inputText6 == "" ||
        inputText5 == "" ||
        inputText4 === "" ||
        inputText3 === "" ||
        inputText2 === "" ||
        inputText1 === "" ||
        isButtonDisabled === false)
    ) {
      failed();
    } else {
      const video = videoRef.current;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth / 2;
      canvas.height = video.videoHeight / 2;
      const ctx = canvas.getContext("2d");

      const audioCtx = new AudioContext();
      const dest = audioCtx.createMediaStreamDestination();

      const videoStream = canvas.captureStream();
      const audioStream = dest.stream;

      ///

      console.log("User agent:", navigator.userAgent);
      let mimeType;

      if (
        navigator.userAgent.includes("Safari") &&
        !navigator.userAgent.includes("Chrome")
      ) {
        console.log("Safari detected");
        mimeType = 'video/mp4; codecs="avc1.42E01E"';
      } else if (navigator.userAgent.includes("Chrome")) {
        console.log("Chrome detected");
        mimeType = "video/webm";
      } else {
        console.log("Non-Safari and Non-Chrome browser detected");
        // Set a default MIME type or handle unsupported browsers accordingly
      }

      const recorder = new MediaRecorder(
        new MediaStream([
          videoStream.getVideoTracks()[0],
          audioStream.getAudioTracks()[0],
        ]),
        { mimeType: mimeType }
      );
      const chunks = [];

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      recorder.onstop = async () => {
        const blob = new Blob(chunks, { type: "video/webm" });
        const formData = new FormData();
        formData.append("video", blob, `${inputText1}.webm`);
        console.log(formData.get("video"));

        try {
          setIsLoadingVideo1(true);
          const response = await fetch(
            "https://dummy.digilateral.com/api/auth/videoCon",
            {
              method: "POST",
              body: formData,
            }
          );

          // Display a toast or handle download completion
          downloadcomplete();

          // Calculate the time taken for the download process
          const downloadEndTime = performance.now();
          const downloadTimeMiliseconds = downloadEndTime - downloadStartTime;
          const downloadTimeSec = downloadTimeMiliseconds / 1000;
          const formatTrackedTime = Math.floor(downloadTimeSec) + "seconds";
          console.log("Time Track :", formatTrackedTime);
          console.log("DRNAME BEFORE SUBMIT : ", inputText1);

          //Submit User Usage Tracked Post API...
          // handleUserData(inputText1, formatTrackedTime);

          // handleUserData(inputText1);

          setIsLoadingVideo1(false);

          navigate(`/home/${MRID}`);

          // Create a Blob object from the video data received in the response
          const videoBlob = await response.blob();

          // Create a URL for the video blob
          const videoBlobUrl = URL.createObjectURL(videoBlob);

          /**************************************** New Code ***************************************/

          // const videoOutputFileName = response.headers['NewFilename'];
          // console.log("NewFileName :", videoOutputFileName);

          // Get the size of the video blob in bytes
          const fileSizeBytes = videoBlob.size;

          // Convert file size to megabytes
          const fileSizeMB = (fileSizeBytes / (1024 * 1024)).toFixed(2);

          console.log(`Size: ${fileSizeMB} MB`);
          // console.log(`FileName :${filename}`);

          //Submit User Usage Tracked Post API...
          handleUserData(inputText1, formatTrackedTime, fileSizeMB);

          /**************************************** New Code ***************************************/

          // Create a link element
          const downloadLink = document.createElement("a");
          downloadLink.href = videoBlobUrl;
          downloadLink.download = `${inputText1}.mp4`;
          downloadLink.style.display = "none";

          // Append the link to the document body
          document.body.appendChild(downloadLink);

          // Simulate a click event to trigger the download
          downloadLink.click();

          // Remove the download link from the document body after download
          document.body.removeChild(downloadLink);

          console.log("Video successfully sent to server for download");
        } catch (error) {
          console.error("Error while sending video to server:", error);
        }
      };

      recorder.start();
      video.play();

      //For smooth transition...
      const easeInOutQuad = (t) => {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      };

      // Easing function for smoother rotation (Cubic)
      const easeInOutCubic = (t) => {
        return t < 0.5
          ? 4 * t * t * t
          : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      };

      // For perfect time set....
      let holdImageUntilTime = 6;

      // console.log("date :",batchObjects);
      const drawFrame = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // console.log("WaterMarkObject before use :", watermarkImageObjects);
        if (
          showWatermark &&
          watermarkImageObjects &&
          watermarkImageObjects.length > 0
        ) {
          const elapsedTime = video.currentTime;

          if (elapsedTime <= holdImageUntilTime && holdImageUntilTime > 0) {
            // Display the first image for the specified duration
            const watermarkPosition = { x: 10, y: canvas.height - 110 };
            const bottomMargin = 330;
            const leftMargin = 15;

            watermarkPosition.x += leftMargin;

            const initialY = canvas.height - 110 - bottomMargin;
            const finalY = initialY - 40;
            watermarkPosition.y =
              initialY +
              easeInOutQuad(elapsedTime / holdImageUntilTime) *
                (finalY - initialY);

            const watermarkSize = 300;
            let rotateAngle = -Math.PI / 12;

            if (elapsedTime > 0 && elapsedTime <= 7) {
              const rotationStartTime = 1;
              const rotationDuration = 5;
              const rotateProgress =
                (elapsedTime - rotationStartTime) / rotationDuration;
              const rotateAngleOffset = Math.PI / 12;
              rotateAngle += easeInOutQuad(rotateProgress) * rotateAngleOffset;

              ctx.save();
              ctx.translate(
                watermarkPosition.x + watermarkSize / 2,
                watermarkPosition.y + watermarkSize / 2
              );
              ctx.rotate(rotateAngle);
              watermarkPosition.x -= watermarkSize / 2;
              watermarkPosition.y -= watermarkSize / 2;
            }

            const watermark = watermarkImageObjects[0];
            const watermarkWidth = watermarkSize;
            const watermarkHeight = watermarkSize;

            // Draw the border around the watermark
            ctx.strokeStyle = "white"; // Set border color to white
            ctx.lineWidth = 12; // Set border width to 12px (or adjust as needed)

            // Draw the border
            ctx.strokeRect(
              watermarkPosition.x - 6, // Adjusted x position for border
              watermarkPosition.y - 6, // Adjusted y position for border
              watermarkWidth + 12, // Add 12 to width for border
              watermarkHeight + 12 // Add 12 to height for border
            );

            ctx.drawImage(
              watermark,
              watermarkPosition.x,
              watermarkPosition.y,
              watermarkWidth,
              watermarkHeight
            );

            // Add static text below the watermark
            const staticText = "Dr." + inputText1;
            const topMargin = 42;

            const staticTextX = watermarkPosition.x + watermarkWidth / 2;
            const staticTextY =
              watermarkPosition.y + watermarkHeight + topMargin;

            // Customize text appearance
            ctx.font = "bold 33px 'Poppins', sans-serif";
            ctx.fillStyle = "black";
            ctx.textAlign = "center";

            // Draw the static text
            ctx.fillText(staticText, staticTextX, staticTextY);

            if (elapsedTime <= holdImageUntilTime) {
              ctx.restore();
            }

            // Check if the specified duration has passed, and update the variable
            if (elapsedTime >= holdImageUntilTime) {
              holdImageUntilTime = 0; // Set to 0 to indicate that the duration has passed
            }
          } else if (elapsedTime > 6 && elapsedTime <= 11) {
            const zoomDuration = 1.5;
            const holdDuration = 10;

            if (elapsedTime <= 6 + zoomDuration) {
              // Code to execute during the zoom-in phase for the second watermark
              const zoomProgress = (elapsedTime - 6) / zoomDuration;
              const initialSize = 0;
              const finalSize = 250;
              const zoomedSize =
                initialSize +
                easeInOutQuad(zoomProgress) * (finalSize - initialSize);
              const zoomedX = canvas.width / 2 - zoomedSize / 2;
              const zoomedY = canvas.height / 2 - zoomedSize / 2;

              // Calculate watermark position
              const watermarkPosition = {
                x: zoomedX,
                y: zoomedY,
              };

              // Calculate watermark width and height
              const watermarkWidth = zoomedSize;
              const watermarkHeight = zoomedSize;

              // Draw the border around the watermark
              ctx.strokeStyle = "white"; // Set border color to white
              ctx.lineWidth = 12; // Set border width to 12px (or adjust as needed)

              // Draw the border
              ctx.strokeRect(
                watermarkPosition.x - 6, // Adjusted x position for border
                watermarkPosition.y - 6, // Adjusted y position for border
                watermarkWidth + 12, // Add 12 to width for border
                watermarkHeight + 12 // Add 12 to height for border
              );

              ctx.drawImage(
                watermarkImageObjects[1],
                watermarkPosition.x,
                watermarkPosition.y,
                watermarkWidth,
                watermarkHeight
              );
            } else if (elapsedTime <= 6 + zoomDuration + holdDuration) {
              // Code to execute during the hold phase

              // Display the second watermark at the center
              const zoomedSize = 250; // Adjust this value to the final zoomed size
              const zoomedX = canvas.width / 2 - zoomedSize / 2;
              const zoomedY = canvas.height / 2 - zoomedSize / 2;

              // Draw the border around the second watermark
              ctx.strokeStyle = "white"; // Set border color to white
              ctx.lineWidth = 12; // Set border width to 12px (or adjust as needed)

              // Draw the border
              ctx.strokeRect(
                zoomedX - 6, // Adjusted x position for border
                zoomedY - 6, // Adjusted y position for border
                zoomedSize + 12, // Add 12 to width for border
                zoomedSize + 12 // Add 12 to height for border
              );
              ctx.drawImage(
                watermarkImageObjects[1],
                zoomedX,
                zoomedY,
                zoomedSize,
                zoomedSize
              );

              // Calculate positions for the third and fourth watermarks
              const thirdImage = watermarkImageObjects[2];
              const fourthImage = watermarkImageObjects[3];
              const distance = 35;

              // Display the third watermark at the left side with zoom-in and rotation effect
              const thirdZoomProgress = Math.min(
                1,
                (elapsedTime - (6 + zoomDuration)) / zoomDuration
              );
              const zoomedSizeThird =
                100 + easeInOutQuad(thirdZoomProgress) * (zoomedSize - 100);
              const thirdImageX = zoomedX - distance - zoomedSizeThird;
              const thirdImageY = zoomedY - (zoomedSizeThird - zoomedSize) / 2;
              const thirdRotation =
                Math.PI * 2 * easeInOutQuad(thirdZoomProgress); // Rotate a complete circle

              // Draw the border around the third watermark
              ctx.save(); // Save the current transformation state
              ctx.strokeStyle = "white"; // Set border color to white
              ctx.lineWidth = 12; // Set border width to 12px (or adjust as needed)

              ctx.translate(
                thirdImageX + zoomedSizeThird / 2,
                thirdImageY + zoomedSizeThird / 2
              );
              ctx.rotate(thirdRotation);

              ctx.strokeRect(
                -zoomedSizeThird / 2 - 6, // Adjusted x position for border
                -zoomedSizeThird / 2 - 6, // Adjusted y position for border
                zoomedSizeThird + 12, // Add 12 to width for border
                zoomedSizeThird + 12 // Add 12 to height for border
              );

              ctx.restore(); // Restore the previous transformation state

              // Draw the third watermark image
              ctx.drawImage(
                thirdImage,
                thirdImageX,
                thirdImageY,
                zoomedSizeThird,
                zoomedSizeThird
              );

              // Display the fourth watermark at the right side with zoom-in and rotation effect
              const fourthZoomProgress = Math.min(
                1,
                (elapsedTime - (6 + zoomDuration)) / zoomDuration
              );
              const zoomedSizeFourth =
                100 + easeInOutQuad(fourthZoomProgress) * (zoomedSize - 100);
              const fourthImageX = zoomedX + zoomedSize + distance;
              const fourthImageY =
                zoomedY - (zoomedSizeFourth - zoomedSize) / 2;
              const fourthRotation =
                -Math.PI * 2 * easeInOutQuad(fourthZoomProgress); // Rotate a complete circle

              // Draw the border around the fourth watermark
              ctx.save(); // Save the current transformation state
              ctx.strokeStyle = "white"; // Set border color to white
              ctx.lineWidth = 12; // Set border width to 12px (or adjust as needed)

              ctx.translate(
                fourthImageX + zoomedSizeFourth / 2,
                fourthImageY + zoomedSizeFourth / 2
              );
              ctx.rotate(fourthRotation);

              ctx.strokeRect(
                -zoomedSizeFourth / 2 - 6, // Adjusted x position for border
                -zoomedSizeFourth / 2 - 6, // Adjusted y position for border
                zoomedSizeFourth + 12, // Add 12 to width for border
                zoomedSizeFourth + 12 // Add 12 to height for border
              );

              ctx.restore(); // Restore the previous transformation state

              // Draw the fourth watermark image
              ctx.drawImage(
                fourthImage,
                fourthImageX,
                fourthImageY,
                zoomedSizeFourth,
                zoomedSizeFourth
              );

              ctx.restore();
            }
          } else if (elapsedTime > 11 && elapsedTime <= 16) {
            const watermarkSize = 300;
            const moveDuration = 1.5; // Adjust the duration for upward movement
            const rotateDuration = 3; // Adjust the duration for rotation
            const totalDuration = moveDuration + rotateDuration;
            const totalProgress = Math.min(
              1,
              (elapsedTime - 11) / totalDuration
            );

            const fixedRightMargin = 50; // Set your desired right margin value

            // Calculate the initial position at the bottom-right with fixed right margin
            const initialX =
              canvas.width - 10 - watermarkSize - fixedRightMargin;
            const initialY = canvas.height - 10 - watermarkSize;

            // Calculate the final position for upward movement
            const finalY = 100;

            // Interpolate the current position based on the total progress
            let currentX = initialX;
            let currentY =
              initialY - easeInOutQuad(totalProgress) * (initialY - finalY);

            // Save the current transformation state
            ctx.save();

            // Translate to the watermark position
            ctx.translate(
              currentX + watermarkSize / 2,
              currentY + watermarkSize / 2
            );

            // If in the rotation phase, calculate the rotation angle
            if (totalProgress >= 1 - rotateDuration / totalDuration) {
              const rotateProgress =
                (totalProgress - (1 - rotateDuration / totalDuration)) /
                (rotateDuration / totalDuration);
              const rotateAngle =
                (Math.PI / 12) * easeInOutQuad(rotateProgress);

              // Rotate the watermark to the right side
              ctx.rotate(rotateAngle);
            }

            // Draw the border around the watermark
            ctx.strokeStyle = "white"; // Set border color to white
            ctx.lineWidth = 26; // Set border width to 12px (or adjust as needed)

            // Draw the border with increased thickness
            ctx.strokeRect(
              -watermarkSize / 2 - 10, // Adjusted x position for border
              -watermarkSize / 2 - 10, // Adjusted y position for border
              watermarkSize + 20, // Add 20 to width for border
              watermarkSize + 20 // Add 20 to height for border
            );

            // Draw the watermark image inside the border
            ctx.drawImage(
              watermarkImageObjects[0],
              -watermarkSize / 2 - 10, // No adjustment needed for x position
              -watermarkSize / 2 - 10, // No adjustment needed for y position
              watermarkSize + 20, // Width of the image
              watermarkSize + 20 // Height of the image
            );

            // Calculate the position for the static text after transformations
            const staticText = "Dr." + inputText1;
            const topMargin = 52;

            const staticTextX = 0; // Centered horizontally
            const staticTextY = watermarkSize / 2 + topMargin;

            // Customize text appearance
            ctx.font = "bold 33px 'Poppins', sans-serif";
            ctx.fillStyle = "black"; // Set your desired text color
            ctx.textAlign = "center";

            // Draw the static text
            ctx.fillText(staticText, staticTextX, staticTextY);

            // Restore the previous transformation state
            ctx.restore();
          } else if (elapsedTime > 16 && elapsedTime <= 22) {
            const watermarkSize = 255;
            const moveDuration = 1.8;
            const pulseFrequency = 8;
            const pulseAmplitude = 8;

            if (elapsedTime <= 16 + moveDuration) {
              // Code to execute during the upward movement phase
              const moveProgress = (elapsedTime - 16) / moveDuration;
              const initialY = canvas.height + watermarkSize; // Start from below the canvas
              const finalY = canvas.height - 400; // Adjust this value for the final position (move more above)
              const currentY =
                initialY - easeInOutQuad(moveProgress) * (initialY - finalY);

              // Draw the border around the watermark
              ctx.strokeStyle = "white"; // Set border color to white
              ctx.lineWidth = 12; // Set border width to 12px (or adjust as needed)

              // Draw the border
              ctx.strokeRect(
                50 - 6, // Adjusted x position for border
                currentY - 6, // Adjusted y position for border
                watermarkSize + 12, // Add 12 to width for border
                watermarkSize + 12 // Add 12 to height for border
              );

              // Draw watermark 2 from bottom-left
              ctx.drawImage(
                watermarkImageObjects[1],
                50 - 6,
                currentY - 6,
                watermarkSize + 12,
                watermarkSize + 12
              );

              // Calculate move progress for watermark 3 and watermark 4
              const watermark3X = canvas.width / 2 - watermarkSize / 2;
              const watermark4X = canvas.width - watermarkSize - 50;
              const initialY3 = canvas.height + watermarkSize;
              const initialY4 = canvas.height + watermarkSize;
              const finalY3 = canvas.height - 400; // The position where watermark 3 and 4 should hold

              // Calculate the current position for watermark 3 and 4
              const currentY3 =
                initialY3 - easeInOutQuad(moveProgress) * (initialY3 - finalY3);
              const currentY4 =
                initialY4 - easeInOutQuad(moveProgress) * (initialY4 - finalY3);

              // Draw the border
              ctx.strokeRect(
                watermark3X - 6, // Adjusted x position for border
                currentY3 - 6, // Adjusted y position for border
                watermarkSize + 12, // Add 12 to width for border
                watermarkSize + 12 // Add 12 to height for border
              );

              // Draw watermark 3 from bottom-middle with easing
              ctx.drawImage(
                watermarkImageObjects[2],
                watermark3X,
                currentY3,
                watermarkSize,
                watermarkSize
              );

              // Draw the border
              ctx.strokeRect(
                watermark4X - 6, // Adjusted x position for border
                currentY4 - 6, // Adjusted y position for border
                watermarkSize + 12, // Add 12 to width for border
                watermarkSize + 12 // Add 12 to height for border
              );

              // Draw watermark 4 from bottom-right with easing
              ctx.drawImage(
                watermarkImageObjects[3],
                watermark4X,
                currentY4,
                watermarkSize,
                watermarkSize
              );
            } else {
              // Draw the border around watermark 2
              ctx.strokeStyle = "white"; // Set border color
              ctx.lineWidth = 11; // Set border width
              ctx.strokeRect(
                50 - 9, // Adjusted x position for border (subtract half of the border width)
                canvas.height - 400 - 9, // Adjusted y position for border (subtract half of the border width)
                watermarkSize + 18, // Add 18 to width for border (twice the border width)
                watermarkSize + 18 // Add 18 to height for border (twice the border width)
              );
              // Draw watermark 2 at the top left
              ctx.drawImage(
                watermarkImageObjects[1],
                50,
                canvas.height - 400,
                watermarkSize,
                watermarkSize
              );

              // Define watermark3X before using it
              const watermark3X = canvas.width / 2 - watermarkSize / 2;

              // Draw the border around watermark 3
              ctx.strokeRect(
                watermark3X - 9, // Adjusted x position for border
                canvas.height - 400 - 9, // Adjusted y position for border
                watermarkSize + 18, // Add 18 to width for border
                watermarkSize + 18 // Add 18 to height for border
              );
              // Draw watermark 3 at the hold position
              ctx.drawImage(
                watermarkImageObjects[2],
                watermark3X,
                canvas.height - 400,
                watermarkSize,
                watermarkSize
              );

              // Draw watermark 4 at the hold position
              const watermark4X = canvas.width - watermarkSize - 50;
              ctx.strokeRect(
                watermark4X - 9,
                canvas.height - 400 - 9,
                watermarkSize + 18,
                watermarkSize + 18
              );
              ctx.drawImage(
                watermarkImageObjects[3],
                watermark4X,
                canvas.height - 400,
                watermarkSize,
                watermarkSize
              );

              // // Apply pulsing effect to watermark 2, 3, and 4 after reaching hold position
              const holdDuration = 3; // Adjust the duration for holding at the top
              const holdProgress = Math.min(
                1,
                (elapsedTime - (16 + moveDuration)) / holdDuration
              );

              // // Apply pulsing effect to watermark 2, 3, and 4
              // ctx.strokeStyle = "white"; // Set border color
              // ctx.lineWidth = 13; // Set border width
              // const pulseSize = Math.sin(elapsedTime * pulseFrequency) * pulseAmplitude;
              // const watermarkWidth = watermarkSize + pulseSize;
              // const watermarkHeight = watermarkSize + pulseSize;

              // // Apply pulsing effect to watermark 2
              // ctx.strokeRect(
              //     50 - 9, // Adjusted x position for border (subtract half of the border width)
              //     canvas.height - 400 - 9, // Adjusted y position for border (subtract half of the border width)
              //     watermarkWidth + 18, // Add 18 to width for border (twice the border width)
              //     watermarkHeight + 18 // Add 18 to height for border (twice the border width)
              // );
              // ctx.drawImage(
              //     watermarkImageObjects[1],
              //     50,
              //     canvas.height - 400,
              //     watermarkWidth,
              //     watermarkHeight
              // );

              // // Apply pulsing effect to watermark 3
              // ctx.strokeRect(
              //     watermark3X - 9, // Adjusted x position for border
              //     canvas.height - 400 - 9, // Adjusted y position for border
              //     watermarkWidth + 18, // Add 18 to width for border
              //     watermarkHeight + 18 // Add 18 to height for border
              // );
              // ctx.drawImage(
              //     watermarkImageObjects[2],
              //     watermark3X,
              //     canvas.height - 400,
              //     watermarkWidth,
              //     watermarkHeight
              // );

              // // Apply pulsing effect to watermark 4
              // ctx.strokeRect(
              //     watermark4X - 9, // Adjusted x position for border
              //     canvas.height - 400 - 9, // Adjusted y position for border
              //     watermarkWidth + 18, // Add 18 to width for border
              //     watermarkHeight + 18 // Add 18 to height for border
              // );
              // ctx.drawImage(
              //     watermarkImageObjects[3],
              //     watermark4X,
              //     canvas.height - 400,
              //     watermarkWidth,
              //     watermarkHeight
              // );

              // Apply pulsing effect to watermark 2, 3, and 4
              ctx.strokeStyle = "white"; // Set border color
              ctx.lineWidth = 19; // Set border width
              const pulseSize =
                Math.sin(elapsedTime * pulseFrequency) * pulseAmplitude;
              const watermarkWidth = watermarkSize + pulseSize;
              const watermarkHeight = watermarkSize + pulseSize;

              // Adjust the position for watermark 2
              ctx.strokeRect(
                50 - 4, // Adjusted x position for border (subtract half of the border width)
                canvas.height - 400 - 4, // Adjusted y position for border (subtract half of the border width)
                watermarkWidth + 8, // Add 8 to width for border (twice the border width)
                watermarkHeight + 8 // Add 8 to height for border (twice the border width)
              );
              ctx.drawImage(
                watermarkImageObjects[1],
                50,
                canvas.height - 400,
                watermarkWidth,
                watermarkHeight
              );

              // Adjust the position for watermark 3
              ctx.strokeRect(
                watermark3X - 4, // Adjusted x position for border
                canvas.height - 400 - 4, // Adjusted y position for border
                watermarkWidth + 8, // Add 8 to width for border
                watermarkHeight + 8 // Add 8 to height for border
              );
              ctx.drawImage(
                watermarkImageObjects[2],
                watermark3X,
                canvas.height - 400,
                watermarkWidth,
                watermarkHeight
              );

              // Adjust the position for watermark 4
              ctx.strokeRect(
                watermark4X - 4, // Adjusted x position for border
                canvas.height - 400 - 4, // Adjusted y position for border
                watermarkWidth + 8, // Add 8 to width for border
                watermarkHeight + 8 // Add 8 to height for border
              );
              ctx.drawImage(
                watermarkImageObjects[3],
                watermark4X,
                canvas.height - 400,
                watermarkWidth,
                watermarkHeight
              );
            }
          } else if (elapsedTime > 23) {
            // Display the watermark at the bottom-left with bottom margin
            const watermarkPosition = { x: 60, y: canvas.height - 130 }; // Updated y position to move upward by 20 pixels

            const bottomMargin = 0;
            const watermarkSize = 300;

            let rotateAngle = -Math.PI / 12; // Initialize rotation angle

            ctx.save(); // Save the current state of the canvas
            const watermark = watermarkImageObjects[0];
            const watermarkWidth = watermarkSize;
            const watermarkHeight = watermarkSize;

            // Add bottom margin to watermark position
            watermarkPosition.y =
              canvas.height - watermarkHeight - bottomMargin - 20;

            // Move the watermark from bottom to top
            const moveDuration = 1; // Adjust the duration as needed
            const moveProgress = Math.min(1, (elapsedTime - 23) / moveDuration);
            const moveDistance = 115; // Adjust the distance as needed
            watermarkPosition.y -= moveDistance * moveProgress;

            // Apply rotation to the watermark
            ctx.translate(
              watermarkPosition.x + watermarkWidth / 2,
              watermarkPosition.y + watermarkHeight / 2
            ); // Translate to the center of the watermark

            if (moveProgress === 1) {
              // Rotate smoothly to the right side
              const rotationDuration = 4; // Adjust the duration of rotation
              const rotationProgress = Math.min(
                1,
                (elapsedTime - 23 - moveDuration) / rotationDuration
              );
              const maxRotateAngle = Math.PI / 13;
              rotateAngle += easeInOutCubic(rotationProgress) * maxRotateAngle;
            }

            ctx.rotate(rotateAngle); // Rotate the watermark
            ctx.translate(
              -(watermarkPosition.x + watermarkWidth / 2),
              -(watermarkPosition.y + watermarkHeight / 2)
            ); // Translate back

            // Draw the border around the watermark
            ctx.strokeStyle = "white"; // Set border color to white
            ctx.lineWidth = 12; // Set border width to 12px (or adjust as needed)

            // Draw the border
            ctx.strokeRect(
              watermarkPosition.x - 6, // Adjusted x position for border
              watermarkPosition.y - 6, // Adjusted y position for border
              watermarkWidth + 12, // Add 12 to width for border
              watermarkHeight + 12 // Add 12 to height for border
            );

            // Draw the watermark
            ctx.drawImage(
              watermark,
              watermarkPosition.x,
              watermarkPosition.y,
              watermarkWidth,
              watermarkHeight
            );

            // Calculate the position for the static text after transformations
            const staticText = "Dr." + inputText1;
            const topMargin = 40;
            const textMargin = 6; // Additional margin between watermark and text

            const staticTextX = watermarkPosition.x + watermarkWidth / 2;
            const staticTextY =
              watermarkPosition.y +
              watermarkHeight / 2 +
              watermarkHeight / 2 +
              topMargin +
              textMargin; // Adjusted staticTextY to be below the watermark

            // Customize text appearance
            ctx.font = "bold 38px 'Poppins', sans-serif";
            ctx.fillStyle = "black"; // Set your desired text color
            ctx.textAlign = "center";

            // Draw the static text
            ctx.fillText(staticText, staticTextX, staticTextY);

            // Restore the previous transformation state
            ctx.restore(); // Restore the saved state of the canvas
          }
        }

        if (!video.ended) {
          setTimeout(() => {
            requestAnimationFrame(drawFrame);
          }, 1000 / 15);
        }
      };

      drawFrame();

      audioCtx.resume().then(() => {
        const audioSource = audioCtx.createMediaElementSource(video);
        audioSource.connect(dest);
      });

      setTimeout(() => {
        recorder.stop();
        video.pause();
      }, video.duration * 1000);
    }
  };

  // Fetch the binary video data from the server......
  useEffect(() => {
    if (
      selectedGroup === "group1" ||
      selectedGroup === "group2" ||
      selectedGroup === "group3" ||
      selectedGroup === "group4" ||
      selectedGroup === "group5" ||
      selectedGroup === "group6"
    ) {
      axios
        .get(` https://dummy.digilateral.com/${videoname}`, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          // Create a Blob from the binary data
          const blob = new Blob([res.data], { type: "video/mp4" });
          setBinaryVideoData(blob);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedGroup]);

  // Update the video source when binary data changes for 1st Video....
  useEffect(() => {
    if (binaryVideoData) {
      // Create a URL for the Blob
      const videoURL = URL.createObjectURL(binaryVideoData);

      // Set the video source to the created URL
      const videoPlayer = videoRef.current;
      if (videoPlayer) {
        videoPlayer.src = videoURL;
      }
    }
  }, [binaryVideoData]);

  // Update the video source when binary data changes for 2nd Video....
  useEffect(() => {
    if (binaryVideoData) {
      // Create a URL for the Blob
      const videoURL = URL.createObjectURL(binaryVideoData);

      // Set the video source to the created URL
      const videoPlayer = videoRef1.current;
      if (videoPlayer) {
        videoPlayer.src = videoURL;
      }
    }
  }, [binaryVideoData]);

  // Update the video source when binary data changes for 3rd Video....
  useEffect(() => {
    if (binaryVideoData) {
      // Create a URL for the Blob
      const videoURL = URL.createObjectURL(binaryVideoData);

      // Set the video source to the created URL
      const videoPlayer = videoRef2.current;
      if (videoPlayer) {
        videoPlayer.src = videoURL;
      }
    }
  }, [binaryVideoData]);

  // Update the video source when binary data changes for 4th Video....
  useEffect(() => {
    if (binaryVideoData) {
      // Create a URL for the Blob
      const videoURL = URL.createObjectURL(binaryVideoData);

      // Set the video source to the created URL
      const videoPlayer = videoRef3.current;
      if (videoPlayer) {
        videoPlayer.src = videoURL;
      }
    }
  }, [binaryVideoData]);

  // Update the video source when binary data changes for 5th Video....
  useEffect(() => {
    if (binaryVideoData) {
      // Create a URL for the Blob
      const videoURL = URL.createObjectURL(binaryVideoData);

      // Set the video source to the created URL
      const videoPlayer = videoRef4.current;
      if (videoPlayer) {
        videoPlayer.src = videoURL;
      }
    }
  }, [binaryVideoData]);

  // Update the video source when binary data changes for 6th Video....
  useEffect(() => {
    if (binaryVideoData) {
      // Create a URL for the Blob
      const videoURL = URL.createObjectURL(binaryVideoData);

      // Set the video source to the created URL
      const videoPlayer = videoRef5.current;
      if (videoPlayer) {
        videoPlayer.src = videoURL;
      }
    }
  }, [binaryVideoData]);

  //Download Multiple videos......
  // const download2Videos = () =>{
  //     downloadWithWatermark1();
  //     downloadWithWatermark2();
  // }

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    };
  }, []);

  return (
    <>
      <div className="flex justify-center items-center flex-col overflow-x-hidden ">
        <div className="w-[100vw]">
          <UserSidebar />
        </div>

        <div className="absolute top-[34px] w-[335px]  sm:w-[650px] lg:w-[700px] xl:left-[450px] sm:left-[60px] lg:left-[150px] ">
          <div className=" w-full">
            <div className="flex flex-row justify-between items-center relative top-[-8px] ">
              <NavLink
                className="p-5 flex relative left-[-36px] "
                to={`/home/${MRID}`}
              >
                <div
                  style={{ backgroundColor: "#F58420", color: "white" }}
                  className="p-[12px] drop-shadow-lg    h-10 w-10   rounded-full relative  top-[-4px] left-[17px] sm:left-[20px]  "
                >
                  <IoMdArrowRoundBack />
                </div>
              </NavLink>
              <div className="text-black text-[20px] font-bold relative left-[-133px] lg:left-[-335px] sm:left-[-300px] top-[-2px]  ">
                <p>Birthday</p>
              </div>
            </div>
            <div className="font-bold text-[11px] top-[-34px] relative left-[105px] sm:left-[251px] w-[250px]   sm:text-center   text-[rgba(158,156,158,1)]">
              <p></p>
            </div>
          </div>
          {/* Handle the dropdown options.... */}
          <div className="">
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Crop Modal"
            >
              <CropFun />
              {/* Close icon */}
              <div className="absolute top-[20px] right-0 bg-orange-500 p-[12px] drop-shadow-lg    h-10 w-10   rounded-full ">
                <button onClick={closeModal} className="">
                  {" "}
                  <FaTimes color={"#fff"} />
                </button>{" "}
              </div>
            </Modal>
            {selectedGroup === "group1" && (
              <div className="w-[335px] border-2 relative sm:w-auto top-[-30px]">
                <div className="video-container">
                  {isLoadingVideo1 && (
                    <div className="spicss">
                      <SyncLoader color="#36D7B7" size={10} margin={2} />
                    </div>
                  )}

                  {!isLoadingVideo1 && (
                    <video
                      className="w-full p-[5px]"
                      ref={videoRef}
                      width="900"
                      poster={Image1}
                      playsInline
                    >
                      <source
                        src={binaryVideoData ? videoRef.current?.src : ""}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>

                <br />
                <label className="relative left-[13px] text-[15px] mt-[10px]">
                  Doctor Name*
                </label>
                <input
                  type="text"
                  className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative left-[9px]"
                  value={inputText1}
                  onChange={handleInputTextChange1}
                  placeholder="Enter Doctor Name(without Dr. prefix)"
                />
                <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                  {remainingCharacters1} of {charLimit}
                </div>
                {/* sm:grid grid-cols-2 gap-4 */}
                <div className="sm:grid grid-cols-2 gap-4">
                  {groupInputs.group1.fileInputs.map((_, index) => (
                    <div
                      className="relative left-[10px]  mb-[10px] "
                      key={index}
                      style={{ display: "inline-block", marginRight: "10px" }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageChange(e, index)}
                      />
                      <p
                        class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                        id="file_input_help"
                      >
                        {" "}
                        PNG, JPG.
                      </p>
                    </div>
                  ))}
                </div>
                <br />

                <button
                  onClick={openModal}
                  className="bg-[#e5e5e5] text-black  rounded-full  transition duration-300 w-full h-[30px]"
                >
                  Crop
                </button>

                <button
                  onClick={addWatermark}
                  className={` ${
                    isButtonDisabled
                      ? "bg-gray-400 cursor-not-allowed text-black mt-[20px] rounded-full transition duration-300 w-full h-[30px]"
                      : "bg-yellow-500  text-black mt-[20px] rounded-full transition duration-300 w-full h-[30px]"
                  }`}
                  disabled={isButtonDisabled}
                >
                  Add Image
                </button>
                <ToastContainer />
                <button
                  onClick={downloadWithWatermark1}
                  className="bg-green-500 text-white mt-[20px] rounded-full  w-full h-[30px]"
                >
                  Download
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Test;
