import './App.css';
import { Route, Routes } from 'react-router-dom';
import Forward from './components/Forward.js';
import Template1 from './components/Template1.js';
import Template2 from './components/Template2.js';
import Template3 from './components/Template3.js';
import Template4 from './components/Template4.js';
import HomePage from './components/HomePage.jsx';
import Login from './components/Login/Login.jsx';
// import Template7 from './components/Template7.js';
import Welcome from './components/Welcome/Welcome.jsx';
import Template5 from './components/Template5.js';
import Profile from './components/profile.jsx';
import VideoProcessingPage from './components/VideoProcessingPage.jsx';
import TeacherDay from './components/TeacherDay.js';
import DoctorDay from './components/DoctorDay.js';
import Grow from './components/Grow.js';
import VideoCleaning from './components/VideoCleaning.js';
import Epilepsy from './components/Epilepsy.js';




function App() {
  return (
    <Routes>
     
      <Route path='/temp/:videoname/:MRID/:name' element={<Template1 />} />
      {/* <Route path='/temp2/:videoname/:MRID/:name' element={<Template2 />} /> */}
      <Route path='/temp2/:MRID' element={<Template2 />} />
      <Route path='/temp3/:videoname/:MRID/:name' element={<Template3 />} />
      {/* <Route path='/temp4/:videoname' element={<Template4/>} /> */}
      <Route path='/temp4/:MRID' element={<Template4/>} />
      <Route path='/temp5/:MRID' element={<Template5/>} />
      {/* <Route path='/temp5/:videoname' element={<Template7/>} /> */}
      <Route path='/team' element={<Forward />} />
      <Route path='/home/:MRID' element={<HomePage/>} />
      <Route path='/' element={<Login/>} />
      <Route path="profile/:MRID" element={<Profile />} />
      <Route path="/video-processing/:MRID" element={<VideoProcessingPage />} />
      <Route path='/temp6/:MRID' element={<TeacherDay/>} />
      <Route path='/temp7/:MRID' element={<DoctorDay/>} />
      <Route path='/temp8/:MRID' element={<Grow/>} />
      <Route path='/temp9/:MRID' element={<VideoCleaning/>} />
      <Route path='/temp10/:MRID' element={<Epilepsy/>} />
      {/* <Route path='/welcome/:MRID' element={<Welcome/>} /> */}
    
    </Routes>
  );
}

export default App;

